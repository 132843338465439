import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";

import { ReactComponent as IconLogo } from "./map.svg";

import {
  Layout,
  Menu,
  Avatar,
  Button,
  Dropdown,
  Input,
  ConfigProvider,
  notification
} from "antd";
import {
  FlagOutlined,
  PieChartOutlined,
  CarOutlined,
  TeamOutlined,
  UserOutlined,
  PlusCircleOutlined,
  DownOutlined,
  HomeOutlined,
  BookOutlined,
  NodeIndexOutlined,
  PartitionOutlined,
  UnorderedListOutlined,
  CopyOutlined,
  TagsOutlined,
  SettingOutlined,
  CalendarOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";

import logoLong from "./logo-enlong.png";
import logoCourt from "./logo-court.png";
import { getUser } from "./Api/Login.js";
import { GlobalContext } from "./GlobalContext";
import { searchCourse } from "./Api/Courses.js";

import moment from "moment";
import "moment/locale/fr";
import frFR from "antd/lib/locale/fr_FR";
import { getOneNetwork } from "./Api/Networks";
import { getOneWhiteLabel } from "./Api/WhiteLabels";

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Search } = Input;

moment.locale("fr");

class AppLayout extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      user: null,
      openKeys: [""],
      redirection: false,
      hover: false,
      courseRedirection: false,
      courseId: null,
      locale: frFR,
      color: "#2FAC66",
      logo: logoLong,
      networkId: null,
      whiteLabelId: null,
      logoCourt: logoCourt
    };

    this.onCollapse = this.onCollapse.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.onSearchCourse = this.onSearchCourse.bind(this);
  }

  /**
   *
   */
  async loadUser() {
    await getUser()
      .then((res) => res.json())
      .then((data) => {
        let networkId = null;
        if (data.user.driver) {
          networkId = data.user.driver.network_id;
        }
        if (data.user.merchant) {
          networkId = data.user.merchant.network_id;
        }
        this.setState({
          user: data.user,
          whiteLabelId: data.user.whiteLabel_id,
          networkId: networkId
        });
        this.context.updateGlobal({
          ...this.context.global,
          profile: data.user,
        });
      });
  }

  async loadTemplate() {
    if (this.state.whiteLabelId !== null && this.state.networkId !== null) {
      Promise.all([
        getOneNetwork(this.state.networkId).then(value => value.json()),
        getOneWhiteLabel(this.state.whiteLabelId).then(value => value.json())
      ]).then(allResponses => {
        let network = allResponses[0];
        let whiteLabel = allResponses[1];

        if (whiteLabel.whiteLabel.color !== null) {
          if (network.network.color !== null) {
            this.setState({ color: network.network.color })
          } else {
            this.setState({ color: whiteLabel.whiteLabel.color })
          }
        } else {
          if (network.color !== null) {
            this.setState({ color: network.network.color })
          }
        }

        if (whiteLabel.whiteLabel.logo !== null) {
          if (network.network.logo !== null) {
            this.setState({
              logo: network.network.logo,
              logoCourt: network.network.logo
            })
          } else {
            this.setState({
              logo: whiteLabel.whiteLabel.logo,
              logoCourt: whiteLabel.whiteLabel.logo
            })
          }
        } else {
          if (network.network.logo !== null) {
            this.setState({
              logo: network.network.logo,
              logoCourt: network.network.logo
            })
          }
        }
      })
    } else if (this.state.whiteLabelId === null && this.state.networkId !== null) {
      getOneNetwork(this.state.networkId)
        .then(value => value.json())
        .then(data => {
          if (data.network.color !== null) {
            this.setState({ color: data.network.color });
          }
          if (data.network.logo !== null) {
            this.setState({
              logo: data.network.logo,
              logoCourt: data.network.logo
            })
          }
        })
    }


  }
  /**
   *
   */
  async componentDidMount() {
    await this.loadUser();
    await this.loadTemplate();
  }

  /**
   *
   * @param {*} collapsed
   */
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  /**
   *
   * @param {*} keys
   */
  onOpenChange = (keys) => {
    if (!this.state.collapsed) {
      let rootSubmenuKeys = [
        "sub1",
        "sub2",
        "sub3",
        "sub4",
        "sub5",
        "sub6",
        "sub7",
        "sub8"
      ];
      const latestOpenKey = keys.find(
        (key) => this.state.openKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openkeys: keys });
      } else {
        this.setState({
          openKeys: latestOpenKey ? [latestOpenKey] : [],
        });
      }
    }
  };

  /**
   *
   */
  onClickDeconnexion() {
    localStorage.clear();
    this.setState({ redirection: true });
  }

  /**
   *
   */
  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  /**
   *
   * @param {*} value
   */
  onSearchCourse(value) {
    let data = {
      id: value,
    };

    searchCourse(data)
      .then((res) => res.json())
      .then((json) => {
        if (!json.course) {
           throw Error();
        }

        this.setState({ courseId: json.course.uuid });
        this.props.history.push("/course/" + json.course.uuid);
      })
      .catch(err => {
        notification["error"]({
            message:
              'La course que vous avez demandé n\'a pas été trouvé.',
          });
      });
  }

  render() {
    const { collapsed } = this.state;
    const { children } = this.props;
    const { location } = this.props;
    const { redirection } = this.state;
    const { courseRedirection } = this.state;
    const { courseId } = this.state;

    let logo = this.state.logo;

    if (redirection) {
      return <Redirect to="/login" />;
    }

    if (courseRedirection) {
      return <Redirect to={"/course/" + courseId} />;
    }

    if (collapsed === true) {
      logo = this.state.logoCourt;
    }

    let name = null;

    if (this.state.user !== null) {
      name = this.state.user.name;
    }

    const menu = (
      <Menu>
        <Menu.Item key="/course/add">
          <Link to="/course/add">
            <CopyOutlined /> Nouvelle course
          </Link>
        </Menu.Item>
        <Menu.Item key="/driver/add">
          <Link to="/driver/add">
            <CopyOutlined /> Nouveau transporteur
          </Link>
        </Menu.Item>
        <Menu.Item key="/merchant/add">
          <Link to="/merchant/add">
            <CopyOutlined /> Nouveau commerçant
          </Link>
        </Menu.Item>
        <Menu.Item key="/customer/add">
          <Link to="/customer/add">
            <CopyOutlined /> Nouveau client
          </Link>
        </Menu.Item>
        <Menu.Item key="/network/add">
          <Link to="/network/add">
            <CopyOutlined /> Nouveau réseau
          </Link>
        </Menu.Item>
        <Menu.Item key="/merchanttheme/add">
          <Link to="/merchanttheme/add">
            <CopyOutlined /> Nouveau thème
          </Link>
        </Menu.Item>
      </Menu>
    );

    let buttonHover = null;
    if (this.state.hover) {
      buttonHover = <div className="logoutButton">Deconnexion</div>;
    }

    const border = {
      borderBottom: "solid 2px" + this.state.color
    }

    return (
      <ConfigProvider locale={this.state.locale} >
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            width="250px"
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
          >
            <div className="logo">
              <img src={logo} className="logoImg" alt="logo" />
            </div>
            <Menu
              defaultSelectedKeys={[location.pathname]}
              mode="inline"
              openKeys={this.state.openKeys}
              onOpenChange={this.onOpenChange}
            >
              <Menu.Item key="/" icon={<PieChartOutlined />}>
                <Link to="/">DashBoard</Link>
              </Menu.Item>
              <Menu.Item
                key="/map"
                icon={<Icon component={IconLogo} style={{ fontSize: 15 }} />}
              >
                <Link to="/map">Carte</Link>
              </Menu.Item>
              <Menu.Item
                  key="/tour"
                  icon={<RedoOutlined />}
              >
                <Link to="/tours">Tournées</Link>
              </Menu.Item>
              <Menu.Item key="/courses/schedule" icon={<CalendarOutlined />}>
                <Link to="/courses/schedule">Progression</Link>
              </Menu.Item>
              <SubMenu key="sub1" icon={<FlagOutlined />} title="Courses">
                <Menu.Item key="/courses">
                  <Link to="/courses">
                    <UnorderedListOutlined /> Toutes
                  </Link>
                </Menu.Item>
                <Menu.Item key="/course/add">
                  <Link to="/course/add">
                    <PlusCircleOutlined /> Nouvelle Course
                  </Link>
                </Menu.Item>
                <Menu.Item key="/courses/importer">
                  <Link to="/courses/importer">
                    {" "}
                    <PlusCircleOutlined /> Importer
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub2" icon={<CarOutlined />} title="Transporteurs">
                <Menu.Item key="/drivers">
                  <Link to="/drivers">
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/driver/add">
                  <Link to="/driver/add">
                    {" "}
                    <PlusCircleOutlined /> Nouveau transporteur
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub3" icon={<HomeOutlined />} title="Commerçants">
                <Menu.Item key="/merchants">
                  <Link to="/merchants">
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/merchant/add">
                  <Link to="/merchant/add">
                    {" "}
                    <PlusCircleOutlined /> Nouveau commerçant
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub4" icon={<TeamOutlined />} title="Clients">
                <Menu.Item key="/customers">
                  <Link to="/customers">
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/customer/add">
                  <Link to="/customer/add">
                    {" "}
                    <PlusCircleOutlined /> Nouveau client
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub6" icon={<PartitionOutlined />} title="Réseaux">
                <Menu.Item key="/networks">
                  <Link to="/networks">
                    {" "}
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/network/add">
                  <Link to="/network/add">
                    {" "}
                    <PlusCircleOutlined /> Nouveau réseau
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub7"
                icon={<NodeIndexOutlined />}
                title="Thèmes commerçants"
              >
                <Menu.Item key="/merchantthemes">
                  <Link to="/merchantthemes">
                    {" "}
                    <UnorderedListOutlined /> Tous
                  </Link>
                </Menu.Item>
                <Menu.Item key="/merchanttheme/add">
                  <Link to="/merchanttheme/add">
                    <PlusCircleOutlined /> Nouveau thème
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub8"
                icon={<SettingOutlined />}
                title="Paramètrages"
              >
                <Menu.Item key="/settingsmerchant" icon={<TagsOutlined />}>
                  <Link to="/settingsmerchant">Tags commerçant</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>

          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: "0", borderBottom: border.borderBottom }}>
              <div>
                <Dropdown overlay={menu}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    style={{
                      color: "white",
                      marginLeft: "20px",
                      border: "solid 1px #2FAC66",
                      backgroundColor: "#2FAC66",
                      padding: "5px 5px",
                    }}
                  >
                    Actions <DownOutlined />
                  </a>
                </Dropdown>
                <div className="searchCourse">
                  <Search
                    placeholder="Rechercher une course"
                    onSearch={this.onSearchCourse}
                    enterButton
                    style={{
                      width: 230,
                      marginTop: "17px",
                      marginLeft: "20px",
                    }}
                  />
                </div>
                <div className="avatar">
                  <Button
                    type="text"
                    onClick={this.onClickDeconnexion}
                    onMouseEnter={this.toggleHover}
                    onMouseLeave={this.toggleHover}
                  >
                    Bienvenue {name}
                    {buttonHover}
                  </Button>
                  <Avatar icon={<UserOutlined />} style={{ backgroundColor: this.state.color }} />
                </div>
              </div>
            </Header>
            {children}
            <Footer style={{ textAlign: "center" }}></Footer>
          </Layout>
        </Layout>
      </ConfigProvider>
    );
  }
}
export default withRouter(AppLayout);
