import React, {useEffect} from 'react';

import {Button, Divider, List, notification, Popconfirm} from 'antd';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';

import TransportRulesDrawerForm from "../../Components/TransportRules/TransportRulesDrawerForm.js";
import {deleteTransportRule, getManyTransportRules} from '../../Api/TransportRules.js';


/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormTransportRulesTab = (props) => {

    let { merchant } = props;

    const [visible, setVisible] = React.useState(false);
    let [transportRules, setTransportRules] = React.useState([]);
    let [transportRuleId, setTransportRuleId] = React.useState(null);
    let [action, setAction] = React.useState('save');

    /**
     * 
     */
    const fetchTransportRules = () => {
        let data = {
            merchant_id: merchant.id
        };
        getManyTransportRules(data)
            .then(res => res.json())
            .then(json => {
                setTransportRules(json);
            })
    }

    /**
     * 
     */
    const openTransportRuleDrawer = () => {
        setAction('save');
        setTransportRuleId(null);
        setVisible(true);
    }

    /**
     * 
     */
    const closeTransportRuleDrawer = () => {
        setVisible(false);
    }

    /**
     * 
     * 
     */
    const onCreatedTransportRule = () => {
        fetchTransportRules();
        setVisible(false);
    }

    /**
     * 
     * @param {*} item 
     * @param {*} rowKey 
     */
    const editOneTransportRule = (id) => {
        setAction('update');
        setTransportRuleId(id);
        setVisible(true);
    }

    /**
     * 
     * @param {*} e 
     * 
     */
    const onClickDeleteTransportRule = (id, e) => {
        e.preventDefault();
        deleteTransportRule(id)
            .then(res => res.json())
            .then(() => {
                notification['success']({
                    message: 'La règle de transport a bien été supprimée'
                });
                fetchTransportRules();
            })
    }

    /**
     * 
     */
    useEffect(() => {
        fetchTransportRules();
    }, [])

    return (
        <div>
            <Button type="primary" onClick={openTransportRuleDrawer}>
                Ajouter une règle
            </Button>
            <Divider></Divider>
            <List
                size="small"
                dataSource={transportRules}
                renderItem={(item) => {
                    const translate = {
                        bike: 'Vélo',
                        walk: 'A pied',
                        cargoBike: 'Vélo cargo',
                        motorbike: 'Scooter ou moto',
                        car: 'Voiture',
                        bus: 'Bus',
                        tram: 'Tram',
                        commercialVehicle: 'Véhicule commercial',
                        all: 'Tous',
                    };
                    let transport = null;
                    let distance = null;
                    let weigth = null;
                    let volume = null;
                    let side = null;
                    if (item.transport === 'all') {
                        transport = 'Tous moyens de transport ';
                    } else if (item.transport === 'walk') {
                        transport = translate[item.transport];
                    } else {
                        transport = 'En  ' + translate[item.transport];
                    }
                    if (item.distance === null || item.distance === undefined) {
                        distance = '';
                    } else {
                        distance = 'une distance de ' + item.distance + ' m,  ';
                    }
                    if (item.weigth === null || item.weigth === undefined) {
                        weigth = '';
                    } else {
                        weigth = 'un poids de ' + item.weigth + ' g ';
                    }
                    if (item.volume === null || item.volume === undefined) {
                        volume = '';
                    } else {
                        volume = ', un volume de ' + item.volume/1000 + ' dm³';
                    }

                    if (item.side === null || item.side === undefined) {
                        side = '';
                    } else {
                        side = ', un coté de ' + item.side + ' cm';
                    }
                    let text = 'Pour' + distance + weigth + volume + side + ' : ';
                    return (
                        <List.Item
                            actions={[
                                <Button size="small" type="primary" onClick={() => editOneTransportRule(item.id)}><FormOutlined /></Button>,
                                <Popconfirm
                                    title="Etes-vous sûr de vouloir supprimer cette règle ?"
                                    onConfirm={(e) => onClickDeleteTransportRule(item.id, e)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button size="small" type="primary"><DeleteOutlined /></Button>
                                </Popconfirm>

                            ]}
                            key={item.id}
                        >
                            <List.Item.Meta
                                title={text}
                            />
                            <div>{transport}</div>
                        </List.Item>
                    )
                }}
            />
            <TransportRulesDrawerForm
                visible={visible}
                onCloseTransportRuleDrawer={closeTransportRuleDrawer}
                onCreatedTransportRule={onCreatedTransportRule}
                transportRuleId={transportRuleId}
                action={action}
                merchant={merchant}
            />
        </div>
    )
}

export default FormTransportRulesTab;
