import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import moment from 'moment';

import {Button, Col, Divider, Form, Input, Row, Select, Space} from 'antd';

import {getManyWhiteLabels} from '../../Api/WhiteLabels.js';
import {getManyNetworks} from '../../Api/Networks.js';
import {getManyMerchantThemes} from '../../Api/MerchantThemes.js';
import {getManyTags} from '../../Api/Settings.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Option } = Select;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormCommerceTab = (props) => {

    const { merchant, user } = props;

    const [form] = Form.useForm();

    const [location, setLocation] = React.useState(null);
    const [valueWhiteLabel, setValueWhiteLabel] = React.useState(null);

    const [dataWhiteLabel, setDataWhiteLabel] = React.useState([]);
    const [dataNetwork, setDataNetwork] = React.useState([]);
    const [dataMerchantTheme, setDataMerchantTheme] = React.useState([]);
    const [dataTags, setDataTags] = React.useState([]);

    /**
     * 
     * @param {*} location 
     */
    const onChangeLocation = (location) => {
        setLocation(location);

        geocodeByAddress(location.label)
            .then(results => {
                let zipCode = "";
                let addressResults = results[0].address_components;
                addressResults.map(addressResult => {
                    let types = addressResult.types;
                    types.map(type => {
                        if (type === "postal_code") {
                            zipCode = addressResult.long_name
                        }
                    })
                });

                form.setFieldsValue({
                    address: location.label,
                    zip_code: zipCode
                });
            });

        form.setFieldsValue({ address: location.label });
    };

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        values.address = location.label;
        geocodeByAddress(location.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                let newMerchant = (merchant !== null) ? { ...merchant } : {};

                newMerchant.name = values.name;
                newMerchant.address = values.address;
                newMerchant.latitude = lat;
                newMerchant.longitude = lng;
                newMerchant.phone = values.phone;
                newMerchant.socialName = values.social_name;
                newMerchant.ownerFirstName = values.owner_firstName;
                newMerchant.ownerLastName = values.owner_lastName;
                newMerchant.ownerPhone = values.owner_phone;
                newMerchant.ownerEmail = values.owner_email;
                newMerchant.zip_code = values.zip_code;
                newMerchant.attributed_merchant_tags = values.tags_id;
                newMerchant.owner_password = values.owner_password;
                newMerchant.owner_confirmation_password = values.owner_confirmation_password;
                newMerchant.tva = (values.tva) ? values.tva : "-";
                newMerchant.siret = (values.siret) ? values.siret : "-";
                newMerchant.legalForm = (values.legal_form) ? values.legal_form : "-";

                newMerchant.whiteLabel_id = user.whiteLabel_id;
                if (!user.whiteLabel_id && values.whiteLabel_id) {
                    newMerchant.whiteLabel_id = values.whiteLabel_id;
                }

                if (values.network_id) {
                    newMerchant.network_id = values.network_id;
                }

                if (values.merchantTheme_id) {
                    newMerchant.merchantTheme_id = values.merchantTheme_id;
                }

                if (values.merchantTheme_id === undefined) {
                    newMerchant.merchantTheme_id = null;
                }

                props.formSubmit(newMerchant);
            });
    };

    /**
     * 
     * @param {*} value 
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataWhiteLabel(json.results);
                })
        }
    }

    /**
     * 
     * @param {*} value
     */
    const handleChangeWhiteLabel = (value) => {
        if (value !== undefined) {
            if (form.getFieldValue('network_id') !== undefined) {
                form.setFieldsValue({ network_id: null });
            }
            setDataNetwork([]);
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value,
                whiteLabel_id: valueWhiteLabel,
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataNetwork(json.results);
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    const handleSearchMerchantTheme = (value) => {
        if (value.length > 2) {
            getManyMerchantThemes({
                term: value,
                whiteLabel_id: valueWhiteLabel,
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchantTheme(json.results);
                });
        }
    };

    /**
     * 
     */
    const fetchTag = () => {
        getManyTags()
            .then((res) => res.json())
            .then((json) => {
                setDataTags(json.results);
            });
    };

    /**
     * 
     */
    useEffect(() => {
        let formValues = {
            name: null,
            date: moment().format('DD/MM/YYYY'),
            social_name: null,
            legal_form: null,
            siret: null,
            tva: null,
            address: null,
            zip_code: null,
            phone: null,
            latitude: null,
            longitude: null,
            owner_firstName: null,
            owner_lastName: null,
            owner_phone: null,
            owner_email: null,
            owner_password: null,
            owner_confirmation_password: null,
            network_id: null,
            merchantTheme_id: null,
            whiteLabel_id: null,
            tags_id: []
        };

        fetchTag();

        if (user && user.whiteLabel_id && !merchant) {
            formValues.whiteLabel_id = user.whiteLabel_id;
            setValueWhiteLabel(user.whiteLabel_id);
        }

        if (merchant) {
            formValues.name = merchant.name;
            formValues.date = moment(merchant.created_at.date).format('DD/MM/YYYY');
            formValues.social_name = merchant.socialName;
            formValues.legal_form = merchant.legalForm;
            formValues.siret = merchant.siret;
            formValues.tva = merchant.tva;
            formValues.address = merchant.address;
            formValues.zip_code = merchant.zip_code;
            formValues.phone = merchant.phone;
            formValues.latitude = merchant.latitude;
            formValues.longitude = merchant.longitude;
            formValues.owner_firstName = merchant.ownerFirstName;
            formValues.owner_lastName = merchant.ownerLastName;
            formValues.owner_phone = merchant.ownerPhone;
            formValues.owner_email = merchant.ownerEmail;
            formValues.tags_id = merchant.tags.map(tag => tag.id);

            if (merchant.white_label) {
                formValues.whiteLabel_id = merchant.white_label.id;
                setValueWhiteLabel(merchant.white_label.id);

                let newWhiteLabels = [...dataWhiteLabel];
                newWhiteLabels.push(merchant.white_label);
                setDataWhiteLabel(newWhiteLabels);
            }

            if (merchant.network) {
                formValues.network_id = merchant.network.id;

                let newNetworks = [...dataNetwork];
                newNetworks.push(merchant.network);
                setDataNetwork(newNetworks);
            }

            if (merchant.merchant_theme) {
                formValues.merchantTheme_id = merchant.merchant_theme.id;

                let newMerchantThemes = [...dataMerchantTheme];
                newMerchantThemes.push(merchant.merchant_theme);
                setDataMerchantTheme(newMerchantThemes);
            }

            setLocation({
                "label": formValues.address,
                "value": {}
            });
        }

        form.setFieldsValue(formValues);
    }, [merchant]);

    const optionWhiteLabel = dataWhiteLabel.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>);
    const optionNetwork = dataNetwork.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>);
    const optionMerchantTheme = dataMerchantTheme.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>);
    const optionTag = dataTags.map(d => <Option key={d.id} value={d.id}>{d.tag}</Option>);

    let selectWhiteLabel = null;

    if (user && !user.whiteLabel_id) {
        selectWhiteLabel = (
            <Form.Item {...layout} label="Marque blanche" name="whiteLabel_id" >
                <Select
                    showSearch
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearchWhiteLabel}
                    onChange={handleChangeWhiteLabel}
                    notFoundContent={null}
                    allowClear
                >
                    {optionWhiteLabel}
                </Select>
            </Form.Item>
        );
    }

    return (
        <Form {...layout} form={form} name="layout" onFinish={onFinish} layout="vertical">
            <Row gutter={20}>
                <Col span={12}>
                    <Divider orientation="left">COMMERCE</Divider>
                    <Form.Item label="Date" name="date">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Nom Commercial"
                        name="name"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer un nom commercial.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Raison sociale"
                        name="social_name"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer votre raison sociale.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Forme juridique" name="legal_form">
                        <Input />
                    </Form.Item>
                    <Form.Item label="SIRET" name="siret">
                        <Input />
                    </Form.Item>
                    <Form.Item label="N° TVA" name="tva">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Adresse"
                        name="address"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer une adresse.' }]}
                    >
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                            selectProps={{
                                value: location,
                                onChange: onChangeLocation,
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['fr'],
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Code Postal" name="zip_code">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Téléphone"
                        name="phone"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer un numéro de téléphone.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Tags" name="tags_id">
                        <Select
                            mode="multiple"
                            placeholder="Sélectionnez vos tags"
                            allowClear
                        >
                            {optionTag}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Divider orientation="left">COMMERCANT</Divider>
                    <Form.Item
                        label="Prénom"
                        name="owner_firstName"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer votre Prénom.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Nom"
                        name="owner_lastName"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer votre Nom.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Téléphone"
                        name="owner_phone"
                        hasFeedback
                        rules={[{ required: true, message: 'Merci d\'indiquer un numéro de téléphone.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="owner_email"
                        hasFeedback
                        rules={[{ type: 'email', required: true, message: 'Merci d\'indiquer votre email.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="owner_password" >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirmation du password"
                        name="owner_confirmation_password"
                        dependencies={['owner_password']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('owner_password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Les 2 mots de passe ne correspondent pas'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {selectWhiteLabel}
                    <Form.Item label="Réseau" name="network_id">
                        <Select
                            showSearch
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={handleSearchNetwork}
                            notFoundContent={null}
                            allowClear
                        >
                            {optionNetwork}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Thème" name="merchantTheme_id">
                        <Select
                            showSearch
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={handleSearchMerchantTheme}
                            notFoundContent={null}
                            allowClear
                        >
                            {optionMerchantTheme}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to="/merchants">Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default FormCommerceTab;
