import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import moment from 'moment';

import {
    Alert,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    message,
    notification,
    Radio,
    Row,
    Select,
    Space,
    TimePicker,
    Tooltip,
    Typography
} from 'antd';
import {
    ArrowRightOutlined,
    DropboxOutlined,
    EditOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import PacketForm from "../../Components/Course/PacketForm";

import {getManyWhiteLabels} from '../../Api/WhiteLabels.js';
import {getManyCustomers, getOneCustomer, postCustomer, updateCustomer} from '../../Api/Customers.js';
import {getManyMerchants, getMerchantOneStore, getMerchantStores, getOneMerchant} from '../../Api/Merchants.js';
import {getNetworkStores} from '../../Api/Networks.js';
import {getCourseDeliveryOptions} from '../../Api/DeliveryOptions.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const layoutGeneral = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Text } = Typography;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormCourseTab = (props) => {

    const [form] = Form.useForm();
    const [formCustomer] = Form.useForm();
    const [formDeliveryOptions] = Form.useForm();

    const { course, customer, merchant, user, stores } = props;

    const [valueWhiteLabel, setValueWhiteLabel] = React.useState(undefined);
    let [dataWhiteLabel, setDataWhiteLabel] = React.useState([]);
    const [valueMerchant, setValueMerchant] = React.useState(undefined);
    let [dataMerchant, setDataMerchant] = React.useState([]);
    const [valueCustomer, setValueCustomer] = React.useState(undefined);
    let [dataCustomer, setDataCustomer] = React.useState([]);
    const [location, setLocation] = React.useState(null);
    const [pickupArea, setPickupArea] = React.useState(null);
    const [deliveryArea, setDeliveryArea] = React.useState(null);
    const [visibleDrawerCustomer, setVisibleDrawerCustomer] = React.useState(false);
    const [visibleDrawerPacket, setVisibleDrawerPacket] = React.useState(false);
    const [visibleDrawerDeliveryOptions, setVisibleDrawerDeliveryOptions] = React.useState(false);
    const [visibleDrawerMerchant, setVisibleDrawerMerchant] = React.useState(false);
    let [packetInformations, setPacketInformations] = React.useState(null);
    let [valueMerchantDrawer, setValueMerchantDrawer] = React.useState(null);
    let [valuePickupArea, setValuePickupArea] = React.useState("merchant");
    let [actionCustomer, setActionCustomer] = React.useState('save');
    let [titleCustomerDrawer, setTitleCustomerDrawer] = React.useState(null);
    let [valueStore, setValueStore] = React.useState(null);
    let [merchantStores, setMerchantStores] = React.useState([]);
    let [networkStores, setNetworkStores] = React.useState([]);
    let [networkId, setNetworkId] = React.useState(null);
    let [merchantThemeId, setMerchantThemeId] = React.useState(null);
    let [dataNetworkDeliveryOptions, setDataNetworkDeliveryOptions] = React.useState([]);
    let [dataMerchantDeliveryOptions, setDataMerchantDeliveryOptions] = React.useState([]);
    let [dataMerchantThemeDeliveryOptions, setDataMerchantThemeDeliveryOptions] = React.useState([]);
    let [selectedDeliveryOptions, setSelectedDeliveryOptions] = React.useState([]);
    let [driverId, setDriverId] = React.useState(null);
    let [commission, setCommission] = React.useState(1);
    const [disabledForm, setDisabledForm] = React.useState(false);

    const statutsDisabledForm = ['isDelivered', 'canceled', 'returned', 'failed', 'pickupFailed'];

    let disablePickupArea = (valuePickupArea !== "custom");
    let disablePickupAreaAdress = (valuePickupArea === "merchant" || valuePickupArea === "store");
    let hiddenSelectStores = (valuePickupArea !== "store");
    let hiddenEditCustomer = (valueCustomer === undefined);
    let hiddenInfoMerchant = (valueMerchant === undefined);
    let disableDeliveryOptions = (valueMerchant === undefined);
    let disableDeliveryAreaAdress = true;

    let selectWhiteLabel = null;

    let resultCourse = null;

    const optionWhiteLabel = dataWhiteLabel.map(d => <Option key={d.id}>{d.name}</Option>);
    const optionMerchant = dataMerchant.map(d => <Option key={d.id}>{d.name}</Option>);
    const optionCustomer = dataCustomer.map(d => {
        let firstName = null;
        if (d.firstname) {
            firstName = d.firstname;
        }
        return <Option key={d.id}>{d.name} {firstName} <ArrowRightOutlined /> {d.address}</Option>
    });

    let merchantDeliveryOptions = null;
    let merchantThemeDeliveryOptions = null;

    const timeFormat = 'HH:mm';

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        if (values.merchant_id === undefined) {
            message.error('Merci de sélectionner un commerçant');
        } else if (values.collect_time === undefined || values.collect_time === null) {
            message.error('Horaire de retrait manquant');
        } else if (values.delivery_date === undefined || values.delivery_date === null) {
            message.error('Date de livraison manquante');
        } else if (values.delivery_time === undefined || values.delivery_time === null) {
            message.error('Horaire de livraison manquant');
        } else if (values.merchant_id.value !== undefined && (valuePickupArea === 'custom_merchant' || valuePickupArea === 'custom') && pickupArea === null) {
            message.error('Merci de sélectionner une adresse de retrait');
        } else if (values.customer_id === undefined) {
            message.error('Merci de sélectionner un client');
        } else {
            let newCourse = (course !== null) ? { ...course } : {};

            newCourse.collect_time = values['collect_time'].format('HH:mm');
            newCourse.delivery_date = values['delivery_date'].format('YYYY-MM-DD');
            newCourse.delivery_time = values['delivery_time'].format('HH:mm');
            newCourse.customer_id = parseInt(values.customer_id.value);
            newCourse.driver_id = driverId;

            if (packetInformations !== null) {
                newCourse.packet_description = packetInformations.packet_description;
                newCourse.packet_price = packetInformations.packet_price;
                newCourse.packet_weight = packetInformations.packet_weight;
                newCourse.packet_height = packetInformations.packet_height;
                newCourse.packet_width = packetInformations.packet_width;
                newCourse.packet_length = packetInformations.packet_length;
                newCourse.packet_volume = packetInformations.packet_volume;
                newCourse.transports = packetInformations.transports;
            }

            if (selectedDeliveryOptions.length === 0) {
                newCourse.delivery_options = [];
            } else {
                newCourse.delivery_options = selectedDeliveryOptions.delivery_options;
            }

            if (values.merchant_id) {
                newCourse.merchant_id = parseInt(values.merchant_id.value);
            } else {
                newCourse.merchant_id = null;
            }

            let selectedWhiteLabelId = user.whiteLabel_id
            if (user.whiteLabel_id === null && values.whiteLabel_id) {
                selectedWhiteLabelId = parseInt(values.whiteLabel_id.value)
            }
            newCourse.whiteLabel_id = selectedWhiteLabelId;

            if (valuePickupArea === "custom_merchant") {
                geocodeByAddress(pickupArea.label)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        newCourse.customStartAddress = pickupArea.label;
                        newCourse.customStartZipCode = values.pickupAreaZipCode;
                        newCourse.customStartLocation = [lat, lng];
                        props.formSubmit(newCourse);
                    })
            } else if (valuePickupArea === 'custom') {
                newCourse.pickupAreaName = values.pickupAreaName;
                newCourse.pickupAreaAddress = pickupArea.label;
                newCourse.pickupAreaContactFirstname = values.pickupAreaContactFirstname;
                newCourse.pickupAreaContactLastname = values.pickupAreaContactLastname;
                newCourse.pickupAreaEmail = values.pickupAreaEmail;
                newCourse.pickupAreaMobilePhone = values.pickupAreaMobilePhone;
                newCourse.pickupAreaPhone = values.pickupAreaPhone;
                newCourse.pickupAreaZipCode = values.pickupAreaZipCode;
                geocodeByAddress(pickupArea.label)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        let pickupAreaLocation = [];
                        pickupAreaLocation.push(lat);
                        pickupAreaLocation.push(lng);
                        newCourse.pickupAreaLocation = pickupAreaLocation;
                        props.formSubmit(newCourse);
                    })
            } else if (valuePickupArea === 'store') {
                newCourse.store_id = parseInt(values.store_id.value);
                newCourse.storeAreaName = values.pickupAreaName;
                newCourse.storeAreaAddress = pickupArea.label;
                newCourse.storeAreaContactFirstname = values.pickupAreaContactFirstname;
                newCourse.storeAreaContactLastname = values.pickupAreaContactLastname;
                newCourse.storeAreaEmail = values.pickupAreaEmail;
                newCourse.storeAreaMobilePhone = values.pickupAreaMobilePhone;
                newCourse.storeAreaPhone = values.pickupAreaPhone;
                newCourse.storeAreaZipCode = values.pickupAreaZipCode;
                geocodeByAddress(pickupArea.label)
                    .then(results => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                        newCourse.storeAreaLat = lat;
                        newCourse.storeAreaLng = lng;
                        props.formSubmit(newCourse);
                    })
            } else {
                props.formSubmit(newCourse);
            }
        }

    };

    /**
     * 
     * @param {*} location 
     */
    const onChangeLocation = (location) => {
        setLocation({
            "label": location.label,
            "value": {}
        });
        geocodeByAddress(location.label)
            .then(results => {
                let zipCode = "";
                let addressResults = results[0].address_components;
                addressResults.map(addressResult => {
                    let types = addressResult.types;
                    types.map(type => {
                        if (type === "postal_code") {
                            zipCode = addressResult.long_name
                        }
                    })
                })
                formCustomer.setFieldsValue({
                    customer_address: location.label,
                    customer_zip_code: zipCode
                })
            })
    }

    /**
     * 
     * @param {*} pickupArea 
     */
    const onChangePickupAreaLocation = (pickupArea) => {
        setPickupArea({
            "label": pickupArea.label,
            "value": {}
        });
        geocodeByAddress(pickupArea.label)
            .then(results => {
                let zipCode = "";
                let addressResults = results[0].address_components;
                addressResults.map(addressResult => {
                    let types = addressResult.types;
                    types.map(type => {
                        if (type === "postal_code") {
                            zipCode = addressResult.long_name
                        }
                    })
                })
                form.setFieldsValue({
                    pickupAreaAddress: pickupArea.label,
                    pickupAreaZipCode: zipCode
                })
            })
    }

    /**
     *
     * @param {*} deliveryArea
     */
    const onChangeDelievryAreaLocation = (deliveryArea) => {
        setDeliveryArea({
            "label": deliveryArea.label,
            "value": {}
        });
        geocodeByAddress(deliveryArea.label)
            .then(results => {
                let zipCode = "";
                let addressResults = results[0].address_components;
                addressResults.map(addressResult => {
                    let types = addressResult.types;
                    types.map(type => {
                        if (type === "postal_code") {
                            zipCode = addressResult.long_name
                        }
                    })
                })
                form.setFieldsValue({
                    deliveryAreaAddress: deliveryArea.label,
                    deliveryAreaZipCode: zipCode
                })
            })
    }

    /**
     * 
     * @param {*} value 
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataWhiteLabel(json.results);
                });
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeWhiteLabel = (values) => {
        if (values !== undefined) {
            setValueWhiteLabel(values.value);
            if (form.getFieldValue('merchant_id') !== undefined) {
                form.setFieldsValue({
                    merchant_id: undefined,
                })
                setValueMerchant(undefined);
                setDataMerchant([])
            }

            if (form.getFieldValue('customer_id') !== undefined) {
                form.setFieldsValue({
                    customer_id: undefined,
                })
                setValueCustomer(undefined);
                setDataCustomer([])
            }
        }
    }

    /**
     * 
     * @param {*} value 
     */
    const handleSearchMerchant = (value) => {
        if (value.length > 2) {
            let data = {
                term: value
            };
            if (valueWhiteLabel !== undefined) {
                data.whiteLabel_id = valueWhiteLabel
            }
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchant(dataMerchant = json.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeMerchant = (values) => {
        setValueMerchant(values.value);
        getOneMerchant(values.value)
            .then((res) => res.json())
            .then((json) => {
                if (valuePickupArea !== 'custom') {
                    form.setFieldsValue({
                        pickupAreaName: json.merchant.name,
                        pickupAreaContactFirstname: json.merchant.ownerFirstName,
                        pickupAreaContactLastname: json.merchant.ownerLastName,
                        pickupAreaPhone: json.merchant.phone,
                        pickupAreaMobilePhone: json.merchant.ownerPhone,
                        pickupAreaEmail: json.merchant.ownerEmail,
                        pickupAreaAddress: json.merchant.address,
                        pickupAreaZipCode: json.merchant.zip_code
                    })
                    setPickupArea({
                        "label": json.merchant.address,
                        "value": {}
                    });
                }
                setNetworkId(json.merchant.network_id);
                setMerchantThemeId(json.merchant.merchantTheme_id);
                if (json.merchant.commission !== null) {
                    setCommission(json.merchant.commission.commission_price);
                }

                if (json.merchant.commission === null && json.merchant.merchant_theme !== null) {
                    if (json.merchant.merchant_theme.commission !== null) {
                        setCommission(json.merchant.merchant_theme.commission.commission_price)
                    } else {
                        if (json.merchant.network !== null) {
                            if (json.merchant.network.commission !== null) {
                                setCommission(json.merchant.network.commission.commission_price)
                            }
                        }
                    }
                }
            })
    }

    /**
     * 
     * @param {*} value 
     */
    const handleSearchCustomer = (value) => {
        if (value.length > 2) {
            getManyCustomers({
                term: value,
                merchant_id: valueMerchant
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataCustomer(dataCustomer = json.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeCustomer = (values) => {
        setValueCustomer(values.key);
        if (values) {
            let id = parseInt(values.key);
            getOneCustomer(id)
                .then((res) => res.json())
                .then((json) => {
                    let customerName = '';
                    let customerFirstName = '';
                    if (json.customer.name) {
                        customerName = json.customer.name;
                    }
                    if (json.customer.firstname) {
                        customerFirstName = json.customer.firstname;
                    }

                    setLocation({
                        "label": json.customer.address,
                        "value": {}
                    });
                    setDeliveryArea({
                        "label": json.customer.address,
                        "value": {}
                    });
                    formCustomer.setFieldsValue({
                        customer_id: {
                            key: json.customer.id,
                            value: json.customer.id,
                            label: customerName + ' ' + customerFirstName,
                        },
                        customer_address: json.customer.address,
                        customer_name: json.customer.name,
                        customer_firstName: json.customer.firstname,
                        customer_latitude: json.customer.latitude,
                        customer_longitude: json.customer.longitude,
                        customer_phone: json.customer.phone,
                        customer_mobilePhone: json.customer.mobilePhone,
                        customer_email: json.customer.email,
                        customer_floor: json.customer.floor,
                        customer_comment: json.customer.comment,
                        customer_intercomCode: json.customer.intercomCode,
                        customer_zip_code: json.customer.zip_code,
                    })
                    form.setFieldsValue({
                        customer_id: {
                            key: json.customer.id,
                            value: json.customer.id,
                            label: customerName + ' ' + customerFirstName,
                        },
                        deliveryAreaFirstname: json.customer.firstname,
                        deliveryAreaLastname: json.customer.name,
                        deliveryAreaAddress: json.customer.address,
                        deliveryAreaPhone: json.customer.phone,
                        deliveryAreaMobilePhone: json.customer.mobilePhone,
                        deliveryAreaZipCode: json.customer.zip_code,
                    })
                })
        }
    }

    /**
     *
     * @param {*} values
     */
    const handleChangeSelectStore = (values) => {
        setValueStore(values.value);  // @TODO utility ?

        getMerchantOneStore(valueMerchant, values.value)
            .then((res) => res.json())
            .then((json) => {
                form.setFieldsValue({
                    pickupAreaName: json.store_name,
                    pickupAreaContactFirstname: json.contact_firstname,
                    pickupAreaContactLastname: json.contact_name,
                    pickupAreaPhone: json.contact_phone,
                    pickupAreaMobilePhone: null,
                    pickupAreaEmail: json.contact_email,
                    pickupAreaAddress: json.address,
                    pickupAreaZipCode: json.zip_code
                })
                setPickupArea({
                    "label": json.address,
                    "value": {}
                })
            })
    }

    /**
     * 
     */
    const showDrawerCustomer = () => {
        setActionCustomer('save');
        setTitleCustomerDrawer('Création d\'un client');
        setVisibleDrawerCustomer(true);
        setLocation({
            "label": null,
            "value": {}
        })
    }

    /**
     * 
     */
    const showDrawerEditCustomer = () => {
        setActionCustomer('update')
        setTitleCustomerDrawer('Information du client');
        setVisibleDrawerCustomer(true);

        getOneCustomer(valueCustomer)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                let customerName = '';
                let customerFirstName = '';
                if (data.customer.name) {
                    customerName = data.customer.name;
                }
                if (data.customer.firstname) {
                    customerFirstName = data.customer.firstname;
                }
                formCustomer.setFieldsValue({
                    customer_address: data.customer.address,
                    customer_name: data.customer.name,
                    customer_firstName: data.customer.firstname,
                    customer_latitude: data.customer.latitude,
                    customer_longitude: data.customer.longitude,
                    customer_phone: data.customer.phone,
                    customer_mobilePhone: data.customer.mobilePhone,
                    customer_email: data.customer.email,
                    customer_floor: data.customer.floor,
                    customer_comment: data.customer.comment,
                    customer_intercomCode: data.customer.intercomCode,
                    customer_zip_code: data.customer.zip_code,
                    customer_id: {
                        key: data.customer.id,
                        value: data.customer.id,
                        label: customerName + ' ' + customerFirstName,
                    }
                });

                setLocation({
                    "label": data.customer.address,
                    "value": {}
                })

            });

    }

    /**
     * 
     */
    const onCloseCustomer = () => {
        setVisibleDrawerCustomer(false);

        formCustomer.resetFields();
    }

    /**
     *
     */
    const onSubmitCustomer = () => {
        let values = formCustomer.getFieldsValue();
        values.customer_address = location.label;
        let newCustomer = {
            merchant_id: valueMerchant,
            name: values.customer_name,
            firstName: values.customer_firstName,
            phone: values.customer_phone,
            mobilePhone: values.customer_mobilePhone,
            email: values.customer_email,
            floor: values.customer_floor,
            intercomCode: values.customer_intercomCode,
            comment: values.customer_comment,
            zip_code: values.customer_zip_code
        };

        if (actionCustomer === 'update') {
            let id = valueCustomer;
            geocodeByAddress(location.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    newCustomer.address = location.label;
                    newCustomer.longitude = lng;
                    newCustomer.latitude = lat;
                    newCustomer.id = id;

                    updateCustomer(id, newCustomer)
                        .then(function (response) {
                            return response.json();
                        }).then((data) => {
                            notification['success']({
                                message: 'Le client a bien été mis à jour'
                            })
                            setVisibleDrawerCustomer(false);
                            let customerName = '';
                            let customerFirstName = '';
                            if (data.customer.name) {
                                customerName = data.customer.name;
                            }
                            if (data.customer.firstname) {
                                customerFirstName = data.customer.firstname;
                            }
                            form.setFieldsValue({
                                customer_id: {
                                    key: data.customer.id,
                                    value: data.customer.id,
                                    label: customerName + ' ' + customerFirstName,
                                },
                                deliveryAreaFirstname: data.customer.firstname,
                                deliveryAreaLastname: data.customer.name,
                                deliveryAreaAddress: data.customer.address,
                                deliveryAreaPhone: data.customer.phone,
                                deliveryAreaMobilePhone: data.customer.mobilePhone,
                                deliveryAreaZipCode: data.customer.zip_code,
                            });
                            setDeliveryArea({
                                "label": data.customer.address,
                                "value": {}
                            });
                        });
                });
        } else {
            geocodeByAddress(location.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    newCustomer.address = location.label;
                    newCustomer.longitude = lng;
                    newCustomer.latitude = lat;

                    postCustomer(newCustomer)
                        .then(function (response) {
                            return response.json();
                        }).then((data) => {
                            notification['success']({
                                message: 'Le client a bien été enregistré'
                            })
                            setVisibleDrawerCustomer(false);
                            let customerName = '';
                            let customerFirstName = '';
                            if (data.customer.name) {
                                customerName = data.customer.name;
                            }
                            if (data.customer.firstname) {
                                customerFirstName = data.customer.firstname;
                            }
                            form.setFieldsValue({
                                customer_id: {
                                    key: data.customer.id,
                                    value: data.customer.id,
                                    label: customerName + ' ' + customerFirstName,
                                },
                                deliveryAreaFirstname: data.customer.firstname,
                                deliveryAreaLastname: data.customer.name,
                                deliveryAreaAddress: data.customer.address,
                                deliveryAreaPhone: data.customer.phone,
                                deliveryAreaMobilePhone: data.customer.mobilePhone,
                                deliveryAreaZipCode: data.customer.zip_code,
                            });
                            setValueCustomer(data.customer.id);
                            setDeliveryArea({
                                "label": data.customer.address,
                                "value": {}
                            });
                        });
                });
        }

    }

    /**
     * 
     */
    const showDrawerPacket = () => {
        setVisibleDrawerPacket(true);
    }

    /**
     * 
     */
    const onClosePacket = () => {
        setVisibleDrawerPacket(false);
    }

    /**
     * 
     * @param {*} values 
     */
    const onSubmitPacket = (values) => {
        setPacketInformations(values);
        setVisibleDrawerPacket(false);
    }

    /**
     * 
     */
    const showDrawerDeliveryOptions = () => {
        setVisibleDrawerDeliveryOptions(true);
        let data = {
            network_id: networkId,
            merchantTheme_id: merchantThemeId,
            merchant_id: valueMerchant
        }
        getCourseDeliveryOptions(data)
            .then(res => res.json())
            .then(data => {
                setDataNetworkDeliveryOptions(data.network_delivery_options);
                if (data.merchant_delivery_options !== null) {
                    setDataMerchantDeliveryOptions(data.merchant_delivery_options);
                }
                if (data.merchantTheme_delivery_options !== null) {
                    setDataMerchantThemeDeliveryOptions(data.merchantTheme_delivery_options);
                }
            })


    }

    /**
     * 
     */
    const onCloseDeliveryOptions = () => {
        setVisibleDrawerDeliveryOptions(false);
    }

    /**
     *
     */
    const onSubmitDeliveryOptions = () => {
        let values = formDeliveryOptions.getFieldsValue();
        setSelectedDeliveryOptions(values);
        setVisibleDrawerDeliveryOptions(false);
    }

    /**
     * 
     */
    const showDrawerMerchant = () => {
        let merchantId = valueMerchant;
        setVisibleDrawerMerchant(true);
        getOneMerchant(merchantId)
            .then((res) => res.json())
            .then((json) => {
                setValueMerchantDrawer(json.merchant)
            })
    }

    /**
     * 
     */
    const onCloseMerchant = () => {
        setVisibleDrawerMerchant(false)
    }

    /**
     * 
     * @param {*} e 
     */
    const onChangePickupArea = e => {
        setValuePickupArea(e.target.value);
        let merchant_id = valueMerchant;
        let network_id = networkId;
        if (e.target.value === "custom_merchant") {
            getOneMerchant(merchant_id)
                .then((res) => res.json())
                .then((json) => {
                    form.setFieldsValue({
                        pickupAreaName: json.merchant.name,
                        pickupAreaContactFirstname: json.merchant.ownerFirstName,
                        pickupAreaContactLastname: json.merchant.ownerLastName,
                        pickupAreaPhone: json.merchant.phone,
                        pickupAreaMobilePhone: json.merchant.ownerPhone,
                        pickupAreaEmail: json.merchant.ownerEmail,
                        pickupAreaAddress: null,
                        pickupAreaZipCode: null,
                    })
                    setPickupArea(null)
                })
        }
        if (e.target.value === 'custom') {
            if (course !== null && course.pickup_area !== null) {
                form.setFieldsValue({
                    pickupAreaName: course.pickup_area.name,
                    pickupAreaContactFirstname: course.pickup_area.contact_firstname,
                    pickupAreaContactLastname: course.pickup_area.contact_lastname,
                    pickupAreaPhone: course.pickup_area.phone,
                    pickupAreaMobilePhone: course.pickup_area.mobile_phone,
                    pickupAreaEmail: course.pickup_area.email,
                    pickupAreaAddress: course.pickup_area.address,
                    pickupAreaZipCode: course.pickup_area.zip_code,
                })
                setPickupArea({
                    "label": course.pickup_area.address,
                    "value": {}
                })
            } else {
                form.setFieldsValue({
                    pickupAreaName: null,
                    pickupAreaContactFirstname: null,
                    pickupAreaContactLastname: null,
                    pickupAreaPhone: null,
                    pickupAreaMobilePhone: null,
                    pickupAreaEmail: null,
                    pickupAreaAddress: null,
                    pickupAreaZipCode: null
                })
                setPickupArea(null)
            }
        }

        if (e.target.value === 'store') {
            Promise.all([
                getMerchantStores(merchant_id).then(value => value.json()),
                getNetworkStores(network_id).then(value => value.json())
            ]).then(allResponses => {
                let merchantStores = allResponses[0];
                let networkStores = allResponses[1];

                setMerchantStores(merchantStores);
                setNetworkStores(networkStores);
            })

            if (course !== null && course.pickup_area !== null) {
                form.setFieldsValue({
                    pickupAreaName: course.pickup_area.name,
                    pickupAreaContactFirstname: course.pickup_area.contact_firstname,
                    pickupAreaContactLastname: course.pickup_area.contact_lastname,
                    pickupAreaPhone: course.pickup_area.phone,
                    pickupAreaMobilePhone: course.pickup_area.mobile_phone,
                    pickupAreaEmail: course.pickup_area.email,
                    pickupAreaAddress: course.pickup_area.address,
                    pickupAreaZipCode: course.pickup_area.zip_code,
                })
                setPickupArea({
                    "label": course.pickup_area.address,
                    "value": {}
                })
            } else {
                form.setFieldsValue({
                    pickupAreaName: null,
                    pickupAreaContactFirstname: null,
                    pickupAreaContactLastname: null,
                    pickupAreaPhone: null,
                    pickupAreaMobilePhone: null,
                    pickupAreaEmail: null,
                    pickupAreaAddress: null,
                    pickupAreaZipCode: null
                })
                setPickupArea(null)
            }
        }

        if (e.target.value === 'merchant') {
            getOneMerchant(merchant_id)
                .then((res) => res.json())
                .then((json) => {
                    if (valuePickupArea !== 'custom') {
                        form.setFieldsValue({
                            pickupAreaName: json.merchant.name,
                            pickupAreaContactFirstname: json.merchant.ownerFirstName,
                            pickupAreaContactLastname: json.merchant.ownerLastName,
                            pickupAreaPhone: json.merchant.phone,
                            pickupAreaMobilePhone: json.merchant.ownerPhone,
                            pickupAreaEmail: json.merchant.ownerEmail,
                            pickupAreaAddress: json.merchant.address,
                            pickupAreaZipCode: json.merchant.zip_code,
                        })
                        setPickupArea({
                            "label": json.merchant.address,
                            "value": {}
                        });
                    }
                })
        }
    }

    /**
     * 
     */
    useEffect(() => {
        if (user !== null && course === null) {
            if (user.whiteLabel_id !== null) {
                setValueWhiteLabel({
                    whiteLabel_id: {
                        key: user.whiteLabel_id,
                        value: user.whiteLabel_id,
                        label: "",
                    }
                })
            }
        }

        if (course !== null) {
            let formValues = {
                collect_time: moment(course.collectDateTime),
                delivery_date: moment(course.deliveryDateTime),
                delivery_time: moment(course.deliveryDateTime),
                distance: course.distance / 1000,
                shortUuid: course.short_uuid,
                driver_price: course.driverPrice,
                merchant_price: course.merchantPrice,
                customer_address: null,
                customer_name: null,
                customer_firstName: null,
                customer_latitude: null,
                customer_longitude: 1,
                customer_phone: null,
                customer_mobilePhone: null,
                customer_email: null,
                customer_floor: null,
                customer_comment: null,
                customer_intercomCode: null,
                customer_zip_code: null,
                customer_id: {
                    key: null,
                    value: null,
                    label: null,
                },
                transports: null,
                merchant_id: {
                    key: null,
                    value: null,
                    label: null,
                },
                whiteLabel_id: {
                    key: null,
                    value: null,
                    label: null,
                },
                pickupAreaAddress: null,
                pickupAreaContactFirstname: null,
                pickupAreaContactLastname: null,
                pickupAreaEmail: null,
                pickupAreaMobilePhone: null,
                pickupAreaName: null,
                pickupAreaPhone: null,
                pickupAreaZipCode: null
            }

            if (course.driver) {
                setDriverId(course.driver.id);
            }

            if (course.white_label !== null) {
                formValues.whiteLabel_id = {
                    key: course.white_label.id,
                    value: course.white_label.id,
                    label: course.white_label.name,
                }

                setValueWhiteLabel({
                    whiteLabel_id: {
                        key: course.white_label.id,
                        value: course.white_label.id,
                        label: course.white_label.name,
                    }
                });
            }

            if (course.pickup_area !== null) {
                formValues.pickupAreaAddress = course.pickup_area.address;
                formValues.pickupAreaContactFirstname = course.pickup_area.contact_firstname;
                formValues.pickupAreaContactLastname = course.pickup_area.contact_lastname;
                formValues.pickupAreaEmail = course.pickup_area.email;
                formValues.pickupAreaMobilePhone = course.pickup_area.mobile_phone;
                formValues.pickupAreaName = course.pickup_area.name;
                formValues.pickupAreaPhone = course.pickup_area.phone;
                formValues.pickupAreaZipCode = course.pickup_area.zip_code;
                formValues.store_id = {
                    key: course.pickup_area.store_id,
                    value: course.pickup_area.store_id,
                    label: course.pickup_area.name
                };

                setValuePickupArea(course.pickup_area.origin);
                setPickupArea({
                    "label": course.pickup_area.address,
                    "value": {}
                });
            }

            if (customer !== null) {
                let customerFirstName = '';
                if (customer.firstname) {
                    customerFirstName = customer.firstname;
                }
                formValues.customer_id = {
                    key: customer.id,
                    value: customer.id,
                    label: customer.name + ' ' + customerFirstName,
                }
                formValues.deliveryAreaFirstname = customer.firstname;
                formValues.deliveryAreaLastname = customer.name;
                formValues.deliveryAreaAddress = customer.address;
                formValues.deliveryAreaPhone = customer.phone;
                formValues.deliveryAreaMobilePhone = customer.mobilePhone;
                formValues.deliveryAreaZipCode = customer.zip_code;
                setDeliveryArea({
                    "label": customer.address,
                    "value": {}
                });
                setValueCustomer(customer.id)
            }

            if (merchant !== null) {
                formValues.merchant_id = {
                    key: merchant.id,
                    value: merchant.id,
                    label: merchant.name,
                }
                setValueMerchant(merchant.id);
                setNetworkId(merchant.network_id);
                setMerchantThemeId(merchant.merchantTheme_id);
            }

            form.setFieldsValue(formValues);

            let formValuesPacket = {
                packet_description: course.packetDescription,
                packet_price: course.packet_price,
                packet_weight: course.packet_weight,
                packet_volume: course.packet_volume,
                packet_height: course.packet_height,
                packet_width: course.packet_width,
                packet_length: course.packet_length
            }

            if (course.transports !== null) {
                formValuesPacket.transports = course.transports;
            }

            setPacketInformations(formValuesPacket);

            if (stores !== null) {
                setMerchantStores(stores.merchantStores);
                setNetworkStores(stores.networkStores);
            }

            if (course.delivery_options.length > 0) {
                let options = course.delivery_options;
                let formValuesOptions = [];
                options.map((option) => {
                    formValuesOptions.push(option.id);
                })
                setSelectedDeliveryOptions({ delivery_options: formValuesOptions });
                formDeliveryOptions.setFieldsValue({
                    delivery_options: formValuesOptions
                })
            }

            setDisabledForm(statutsDisabledForm.includes(course.status));
        } else {
            form.setFieldsValue({
                delivery_date: moment()
            })
        }
    }, [course, customer, merchant, stores]);

    let alertDisabledForm = null;
    if (disabledForm) {
        alertDisabledForm = (
            <Alert
                message="La course étant terminée, il est impossible de modifier les informations impactant la tarification !"
                style={{ margin: '20px 0' }}
                showIcon
            />
        );
    }

    if (user !== null && user.whiteLabel_id === null) {
        selectWhiteLabel = (
            <Form.Item {...layoutGeneral} label="Marque blanche" name="whiteLabel_id">
                <Select
                    labelInValue={true}
                    showSearch
                    value={valueWhiteLabel}
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearchWhiteLabel}
                    onChange={handleChangeWhiteLabel}
                    notFoundContent={null}
                    disabled={disabledForm}
                    allowClear
                >
                    {optionWhiteLabel}
                </Select>
            </Form.Item>
        )
    }

    let merchantDrawer = (
        <Drawer
            title="Informations du commerçant"
            width={720}
            onClose={onCloseMerchant}
            visible={visibleDrawerMerchant}
            bodyStyle={{ paddingBottom: 80 }}
        >
            Aucune information
        </Drawer>
    );

    if (valueMerchantDrawer) {
        merchantDrawer = (
            <Drawer
                title="Informations du commerçant"
                width={720}
                onClose={onCloseMerchant}
                visible={visibleDrawerMerchant}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Row>
                    <Col span={6}>Nom :</Col>
                    <Col span={12}>{valueMerchantDrawer.name}</Col>
                </Row>
                <Row>
                    <Col span={6}>Adresse  :</Col>
                    <Col span={12}>{valueMerchantDrawer.address}</Col>
                </Row>
                <Row>
                    <Col span={6}>Personne à contacter :</Col>
                    <Col span={16}>{valueMerchantDrawer.ownerFirstName} {valueMerchantDrawer.ownerLastName}</Col>
                </Row>
                <Row>
                    <Col span={6}>Email :</Col>
                    <Col span={12}>{valueMerchantDrawer.ownerEmail}</Col>
                </Row>
                <Row>
                    <Col span={6}>Téléphone :</Col>
                    <Col span={12}>{valueMerchantDrawer.phone}</Col>
                </Row>
                <Button type='primary' style={{ marginTop: '20px' }}>
                    <Link to={'/merchant/' + valueMerchantDrawer.id}>Détail</Link>
                </Button>
            </Drawer>
        )
    }

    let customerDrawer = (
        <Drawer
            title={titleCustomerDrawer}
            width={720}
            onClose={onCloseCustomer}
            visible={visibleDrawerCustomer}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space>
                    <Button onClick={onCloseCustomer} style={{ marginRight: 8 }}>
                        {disabledForm ? "Fermer" : "Annuler"}
                    </Button>
                    <Button onClick={onSubmitCustomer} type="primary" disabled={disabledForm}>
                        Enregistrer
                    </Button>
                </Space>
            }
        >
            <Form form={formCustomer} layout="vertical" disabled={disabledForm}>
                <Form.Item
                    label="Nom"
                    name="customer_name"
                    hasFeedback
                    rules={[{
                        required: true,
                        message: "Merci d\'indiquer un nom."
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Prénom" name="customer_firstName">
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="customer_email" >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Adresse"
                    name="customer_address"
                    hasFeedback
                    rules={[{
                        required: true,
                        message: "Merci de renseigner une adresse."
                    }]}
                >
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                        selectProps={{
                            value: location,
                            onChange: onChangeLocation,
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['fr'],
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item label="Code Postal" name="customer_zip_code">
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone" name="customer_phone">
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone mobile" name="customer_mobilePhone">
                    <Input />
                </Form.Item>
                <Form.Item label="Etage" name="customer_floor">
                    <Input />
                </Form.Item>
                <Form.Item label="Interphone" name="customer_intercomCode">
                    <Input />
                </Form.Item>
                <Form.Item label="Commentaire" name="customer_comment">
                    <TextArea rows={12} />
                </Form.Item>
            </Form>
        </Drawer>
    );

    let selectPickupArea = (
        <Alert message="Sélectionnez un commerçant pour choisir votre zone de retrait." type="success" />
    );
    let selectDeliveryArea = (
        <Alert message="Sélectionnez un commerçant pour choisir votre zone de livraison." type="success" />
    );

    if (valueMerchant !== undefined) {
        const disabledCustomerButton = (customer !== null && customer.archived_at !== null);

        selectPickupArea = (
            <div>
                <Space>
                    <Radio.Group onChange={onChangePickupArea} value={valuePickupArea} style={{marginBottom: "20px"}} disabled={disabledForm}>
                        <Radio value="merchant">Chez le commerçant</Radio>
                        <Radio value="custom_merchant">Chez le commerçant à une autre adresse</Radio>
                        <Radio value="custom">A une autre adresse</Radio>
                        <Radio value="store">Depuis un entrepôt</Radio>
                    </Radio.Group>
                </Space>
                <div style={{marginBottom: 20}} hidden={hiddenSelectStores}>
                    <Form.Item {...layout} name="store_id">
                        <Select
                            labelInValue={true}
                            style={{width: 250}}
                            onChange={handleChangeSelectStore}
                            placeholder="Choisissez un entrepôt"
                        >
                            <OptGroup label="Commerçant">
                                {merchantStores.map(merchantStore => <Option
                                    key={merchantStore.id}>{merchantStore.store_name} </Option>)}
                            </OptGroup>
                            <OptGroup label="Réseau">
                                {networkStores.map(networkStore => <Option
                                    key={networkStore.id}>{networkStore.store_name} </Option>)}
                            </OptGroup>
                        </Select>
                    </Form.Item>
                </div>
                <Row gutter={[30, 20]}>
                    <Col span={12}>
                        <Form.Item {...layout} name="pickupAreaName">
                            <Input name="pickupAreaName" placeholder="Nom du commerçant" disabled={disablePickupArea}/>
                        </Form.Item>
                        <Form.Item {...layout} name="pickupAreaAddress">
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                                selectProps={{
                                    value: pickupArea,
                                    onChange: onChangePickupAreaLocation,
                                    isDisabled: disablePickupAreaAdress,
                                    placeholder: 'Choisissez une adresse',
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['fr'],
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="pickupAreaZipCode">
                            <Input name="pickupAreaZipCode" placeholder="Code Postal"
                                   disabled={disablePickupAreaAdress}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item  {...layout} name="pickupAreaContactFirstname">
                                    <Input name="pickupAreaContactFirstname" placeholder="Prénom du contact"
                                           disabled={disablePickupArea}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item  {...layout} name="pickupAreaContactLastname">
                                    <Input name="pickupAreaContactLastname" placeholder="Nom du contact"
                                           disabled={disablePickupArea}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item  {...layout} name="pickupAreaPhone">
                                    <Input name="pickupAreaPhone" placeholder='Téléphone' disabled={disablePickupArea}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item  {...layout} name="pickupAreaMobilePhone">
                                    <Input name="pickupAreaMobilePhone" placeholder='Téléphone portable'
                                           disabled={disablePickupArea}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item {...layout} name="pickupAreaEmail">
                            <Input name="pickupAreaEmail" placeholder='Email' disabled={disablePickupArea}/>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
        selectDeliveryArea = (
            <div>
                <Row gutter={[25, 10]}>
                    <Col span={20}>
                        <Form.Item
                            name="customer_id"
                        >
                            <Select
                                disabled={disabledForm}
                                labelInValue={true}
                                showSearch
                                value={valueCustomer}
                                placeholder="Sélectionnez un client (min. 3 caractères)"
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearchCustomer}
                                onChange={handleChangeCustomer}
                                notFoundContent={null}
                                allowClear
                                style={{marginTop: "22px", marginRight: "25px"}}
                            >
                                {optionCustomer}
                            </Select>
                        </Form.Item>
                        {customerDrawer}
                    </Col>
                    <Col span={2} style={{paddingTop: "20px"}}>
                        <Button type="primary" onClick={showDrawerCustomer} disabled={disabledForm}>
                            <PlusCircleOutlined/>
                        </Button>
                    </Col>
                    <Col span={2} style={{paddingTop: "20px"}}>
                        <Tooltip title={(disabledCustomerButton) ? "client archivé" : null}>
                            <Button
                                type="primary"
                                onClick={showDrawerEditCustomer}
                                hidden={hiddenEditCustomer}
                                disabled={disabledCustomerButton}
                            >
                                <EditOutlined/>
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[30, 20]}>
                    <Col span={12}>
                        <Form.Item  {...layout} name="deliveryAreaFirstname">
                            <Input name="deliveryAreaFirstname" placeholder="Prénom du contact"
                                   disabled={disableDeliveryAreaAdress}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item  {...layout} name="deliveryAreaLastname">
                            <Input name="deliveryAreaLastname" placeholder="Nom du contact"
                                   disabled={disableDeliveryAreaAdress}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[30, 20]}>
                    <Col span={12}>
                        <Form.Item  {...layout} name="deliveryAreaPhone">
                            <Input name="pickupAreaPhone" placeholder='Téléphone' disabled={disableDeliveryAreaAdress}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item  {...layout} name="deliveryAreaMobilePhone">
                            <Input name="pickupAreaMobilePhone" placeholder='Téléphone portable'
                                   disabled={disableDeliveryAreaAdress}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[30, 20]}>
                    <Col span={12}>
                        <Form.Item {...layout} name="deliveryAreaAddress">
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                                selectProps={{
                                    value: deliveryArea,
                                    onChange: onChangeDelievryAreaLocation,
                                    isDisabled: disableDeliveryAreaAdress,
                                    placeholder: 'Choisissez une adresse',
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['fr'],
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="deliveryAreaZipCode">
                            <Input name="deliveryAreaZipCode" placeholder="Code Postal"
                                   disabled={disableDeliveryAreaAdress}/>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }

    if (course !== null) {
        let coursePrice = "Aucune tarification trouvée";
        let merchantPrice = "Aucune tarification trouvée";

        if (course.price !== null) {
            coursePrice = course.price + ' €';
        }

        if (course.merchantPrice !== null) {
            merchantPrice = course.merchantPrice + ' €';
        }

        resultCourse = (
            <div>
                <Divider orientation="left">Résultat de la course</Divider>
                <Row>
                    <Col span={6}>Prix HT de la course (transporteur) : </Col>
                    <Col span={8}>{coursePrice}</Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col span={6}>Prix HT de la course (commerçant) : </Col>
                    <Col span={8}>{merchantPrice}</Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col span={6}>Distance : </Col>
                    <Col span={8}>{course.distance / 1000} km</Col>
                </Row>
            </div>
        );
    }

    let networkDeliveryOptions = (
        <Alert message="Sélectionnez un commerçant pour choisir vos options de livraison." type="success" />
    );
    if (networkId) {
        if (dataNetworkDeliveryOptions.length === 0) {
            networkDeliveryOptions = (<div>Aucune option générale trouvée</div>);
        } else {
            networkDeliveryOptions = dataNetworkDeliveryOptions.map(d => {
                let optionName = d.option_name;
                let optionPrice = null;

                if (d.option_name === null && d.parent !== null) {
                    optionName = d.parent.option_name;
                }

                if (d.option_price !== null) {
                    optionPrice = '(+' + Math.round((d.option_price * commission) * 100) / 100 + ' €)';
                }

                return (
                    <Col span={24}>
                        <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
                    </Col>
                );
            });
        }

        if (dataMerchantDeliveryOptions.length > 0) {
            merchantDeliveryOptions = (
                <div>
                    <Divider orientation='left'>Options du commerçant</Divider>
                    <Row>
                        {dataMerchantDeliveryOptions.map(d => {
                            let optionName = d.option_name;
                            let optionPrice = null;

                            if (d.option_name === null && d.parent !== null) {
                                optionName = d.parent.option_name;
                            }

                            if (d.option_price !== null) {
                                optionPrice = '(+' + Math.round((d.option_price * commission) * 100) / 100 + ' €)';
                            }

                            return (
                                <Col span={24}>
                                    <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            );
        }

        if (dataMerchantThemeDeliveryOptions.length > 0) {
            merchantThemeDeliveryOptions = (
                <div>
                    <Divider orientation='left'>Options du thème commerçant</Divider>
                    <Row>
                        {dataMerchantThemeDeliveryOptions.map(d => {
                            let optionName = d.option_name;
                            let optionPrice = null;

                            if (d.option_name === null && d.parent !== null) {
                                optionName = d.parent.option_name;
                            }
                            if (d.option_price !== null) {
                                optionPrice = '(+' + Math.round((d.option_price * commission) * 100) / 100 + ' €)'
                            }

                            return (
                                <Col span={24}>
                                    <Checkbox value={d.id}>{optionName} {optionPrice}</Checkbox>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            );
        }
    }

    let requiredMark = <Text type="danger">*</Text>;

    return (
        <div>
            {alertDisabledForm}
            <Form form={form} name="layout" onFinish={onFinish} layout="vertical">
                {selectWhiteLabel}
                <Form.Item {...layoutGeneral}
                    label={
                        <div>
                            {requiredMark} Sélectionner un commerçant
                            <Tooltip>
                                <Typography.Link
                                    style={{ marginLeft: 20 }}
                                    onClick={showDrawerMerchant}
                                    hidden={hiddenInfoMerchant}
                                >
                                    <InfoCircleOutlined />
                                </Typography.Link>
                            </Tooltip>
                        </div>
                    }
                    name="merchant_id"
                >
                    <Select
                        labelInValue={true}
                        showSearch
                        value={valueMerchant}
                        placeholder="Entrer au minimum 3 caractères"
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleSearchMerchant}
                        onChange={handleChangeMerchant}
                        notFoundContent={null}
                        disabled={disabledForm}
                        allowClear
                    >
                        {optionMerchant}
                    </Select>
                </Form.Item>
                {merchantDrawer}
                <Divider orientation="left">Zone de Retrait</Divider>
                {selectPickupArea}
                <Divider orientation="left">{requiredMark} Zone de Livraison</Divider>
                {selectDeliveryArea}
                <Divider orientation="left">Plannification</Divider>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label={<div>{requiredMark} Date de livraison</div>}
                            name="delivery_date"
                        >
                            <DatePicker
                                disabled={disabledForm}
                                style={{ width: 250 }}
                                placeholder="Sélectionner une date"
                                format="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<div>{requiredMark} Récupération à partir de</div>}
                            name="collect_time"
                        >
                            <TimePicker
                                disabled={disabledForm}
                                style={{ width: 250 }}
                                placeholder="Sélectionner une heure"
                                minuteStep={5}
                                format={timeFormat}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<div>{requiredMark} Livraison avant</div>}
                            name="delivery_time"
                        >
                            <TimePicker
                                disabled={disabledForm}
                                style={{ width: 250 }}
                                placeholder="Sélectionner une heure"
                                minuteStep={5}
                                format={timeFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Informations complémentaires</Divider>
                <div style={{ marginTop: 30, marginBottom: 30 }}>
                    <Space>
                        <Button type="primary" onClick={showDrawerPacket} className="button-course">
                            <DropboxOutlined /> Colis
                        </Button>
                        <Button type="primary" onClick={showDrawerDeliveryOptions} disabled={disableDeliveryOptions}>
                            <UnorderedListOutlined /> Options de livraison
                        </Button>
                    </Space>
                </div>
                <PacketForm
                    course={course}
                    visible={visibleDrawerPacket}
                    viewMode={disabledForm}
                    onClose={onClosePacket}
                    onSubmit={onSubmitPacket}
                />
                <Drawer
                    title="Options de livraison"
                    width={720}
                    onClose={onCloseDeliveryOptions}
                    visible={visibleDrawerDeliveryOptions}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                        <Space>
                            <Button onClick={onCloseDeliveryOptions} style={{ marginRight: 8 }}>
                                {disabledForm ? "Fermer" : "Annuler"}
                            </Button>
                            <Button onClick={onSubmitDeliveryOptions} type="primary" disabled={disabledForm}>
                                Enregistrer
                            </Button>
                        </Space>
                    }
                >
                    <Form form={formDeliveryOptions} layout="vertical" hideRequiredMark >
                        <Form.Item name="delivery_options">
                            <Checkbox.Group >
                                <Divider orientation='left'>Options Générales</Divider>
                                <Row>
                                    {networkDeliveryOptions}
                                </Row>
                                {merchantThemeDeliveryOptions}
                                {merchantDeliveryOptions}
                            </Checkbox.Group>
                        </Form.Item>
                    </Form>
                </Drawer>
                {resultCourse}
                <Divider />
                <Space>
                    <Button type="primary" htmlType="submit"  disabled={disabledForm}>
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/courses'>Annuler</Link>
                    </Button>
                </Space>
            </Form>
        </div>
    );
};

/**
 *
 * @param {*} props
 * @returns
 */
const FormNotes = (props) => {

    const { course } = props;
    const [form] = Form.useForm();

    const onSubmit = (values) => {
        props.formSubmit(values);
    };

    /**
     *
     */
    useEffect(() => {
        if (course) {
            form.setFieldsValue({ notes: course.notes });
        }
    }, [course]);

    return (
        <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Form.Item name="notes">
                <TextArea rows={8} name="notes" />
            </Form.Item>
            <Space>
                <Button type="primary" htmlType="submit"  disabled={props.viewMode}>
                    Enregistrer
                </Button>
                <Button style={{ backgroundcolor: '#2FAC66' }}>
                    <Link to='/courses'>Annuler</Link>
                </Button>
            </Space>
        </Form>
    );
};

export default FormCourseTab;
export { FormNotes };
