import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {saveAs} from 'file-saver';
import moment from 'moment';

import {
    Alert,
    Avatar,
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Layout,
    message,
    Modal,
    PageHeader,
    Row,
    Select,
    Space,
    Table,
    Tag,
    TimePicker,
    Tooltip
} from 'antd';
import {
    CaretRightOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EyeOutlined,
    FormOutlined,
    PlusCircleOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import {courseCanceled, courseValidate, exportCourses, getList as getCourses, getOneCourse} from '../../Api/Courses';
import {getManyWhiteLabels} from '../../Api/WhiteLabels';
import {GlobalContext} from '../../GlobalContext';

import ModalDriver from '../../Components/Course/ModalDriver';
import DrawerDetailsCourse from '../../Components/Course/DrawerDetailsCourse';
import {translateStatus} from "../../constants";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Content } = Layout;

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';
const layoutForm = {
    style: {
        paddingTop: 10,
    },
    labelAlign: 'left',
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
        style: {
            paddingRight: 20
        }
    },
};
const layoutFormItem = {
    style: {
        marginBottom: 20
    }
};

const defaultFilters = {
    deliveryDates: [moment(), moment()],
    status: ['available', 'assigned', 'started', 'inProgress'],
    pickupTime: [null, null],
    deliveryTime: [null, null],
    whiteLabelId: null
};

/**
 *
 * @param {*} props (filters, user)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();
    const [whiteLabels, setWhiteLabels] = useState([]);

    /**
     *
     * @param {*} value
     */
    const fetchWhiteLabels = (payload) => {
        getManyWhiteLabels(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        throw Error();
                    }
                    return res.json();
                })
                .then((json) => {
                    setWhiteLabels(json.results);
                })
                .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const onSearchWhiteLabel = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value };
        fetchWhiteLabels(payload);
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    let itemWhiteLabel = null;
    if (props.user && !props.user.whiteLabel_id) {
        const whiteLabelOptions = whiteLabels.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>);

        itemWhiteLabel = (
            <Form.Item {...layoutFormItem} name="whiteLabelId" label="Marques blanches">
                <Select
                    showSearch
                    showArrow
                    allowClear
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Entrer au minimum 3 caractères"
                    onSearch={onSearchWhiteLabel}
                >
                    {whiteLabelOptions}
                </Select>
            </Form.Item>
        );
    }

    const handleDeliveryDatesRangeChange = (dates, dateStrings) => {
        let daysNumber = dates[1].endOf('day').diff(dates[0].startOf('day'), 'days') + 1;
        if (daysNumber > 7) {
            form.setFieldsValue({
                deliveryDates: [
                    dates[0],
                    moment(dates[0].start).add(6, 'days')
                ]
            });

            message.warning('La sélection maximale est de 7 jours !');
        }
    };

    return (
        <Form
            {...layoutForm}
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={{ ...props.filters }}
        >
            <Row>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        label="Date de livraison entre le "
                        name="deliveryDates"
                    >
                        <RangePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                            onChange={handleDeliveryDatesRangeChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="pickupTime" label="Heure de retrait">
                        <TimePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="deliveryTime" label="Heure de livraison">
                        <TimePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Heure de début', 'Heure de fin']}
                            format={timeFormat}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="status" label="Status des courses">
                        <Select
                            mode="multiple"
                            placeholder="Sélectionner un ou plusieurs statuts"
                        >
                            <Option value="draft">Brouillon</Option>
                            <Option value="available">Emise</Option>
                            <Option value="assigned">Assignée</Option>
                            <Option value="started">Démarée</Option>
                            <Option value="inProgress">Colis Récupéré</Option>
                            <Option value="isDelivered">Colis livré</Option>
                            <Option value="failed">Echouée (Livraison)</Option>
                            <Option value="pickupFailed">Echouée (Retrait)</Option>
                            <Option value="canceled">Annulée</Option>
                            <Option value="returned">Retournée</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {itemWhiteLabel}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Filtrer
                        </Button>
                        <Button onClick={clearFilter}>
                            Annuler
                        </Button>
                    </Space>
                </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

class Courses extends React.Component {
    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            pagination: {
                current: 1,
                pageSize: 50,
            },
            loading: false,
            dataWhiteLabel: [],
            visibleDrawerCourseInfo: false,
            course: null,
            selectedRowKeys: [],
            selectedRows: [],
            isModalVisible: false,
            uuid: null,
            redirection: false,
            modalAvailableVisible: false, 
            previousPrice: null,
            newPrice: null,
            filters: { ...defaultFilters }
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'short_uuid',
                key: 'short_uuid',
                width: 50
            },
            {
                title: 'Date de livraison',
                dataIndex: 'deliveryDate',
                key: 'deliveryDate',
                render: deliveryDate => {
                    return moment(deliveryDate).format('DD/MM/YYYY');
                }
            },
            {
                title: 'A partir de',
                dataIndex: 'collectTime',
                key: 'collectTime'
            },
            {
                title: 'Avant',
                dataIndex: 'deliveryTime',
                key: 'deliveryTime'
            },
            {
                title: 'Commerçant',
                dataIndex: 'merchant',
                key: 'merchant',
                render: (merchant) => {
                    if (merchant === null) {
                        return;
                    }

                    return <Link to={'/merchant/' + merchant.id}>{merchant.name}</Link>;
                }
            },
            {
                title: 'Client',
                dataIndex: 'customer',
                key: 'customer',
                render: (customer) => {
                    if (customer === null) {
                        return;
                    }

                    if (customer.archived_at !== null) {
                        return (
                            <Tooltip title="client archivé">
                                <span>{customer.firstname} {customer.name}</span>
                            </Tooltip>
                        );
                    }

                    return <Link to={'/customer/' + customer.id}>{customer.firstname} {customer.name}</Link>;
                }
            },
            {
                title: 'Adresse de livraison',
                dataIndex: 'delivery_area',
                key: 'delivery_area',
                render: (delivery_area) => {
                    if (delivery_area === null) {
                        return;
                    }

                    return <p>{delivery_area.address}</p>;
                }
            },
            {
                title: 'Véhicule',
                dataIndex: 'transports',
                key: 'transports',
                render: (transports) => {
                    if (transports === null) {
                        return;
                    }

                    const translate = {
                        bike: 'Vélo',
                        walk: 'A pied',
                        cargoBike: 'Vélo cargo',
                        motorbike: 'Scooter ou moto',
                        car: 'Voiture',
                        bus: 'Bus',
                        tram: 'Tram',
                        commercialVehicle: 'Véhicule commercial',
                        all: 'Tous',
                    };

                    let transportTranslate = [];
                    transports.map((transport) => {
                        transportTranslate.push(translate[transport]);
                    });

                    return <p>{transportTranslate}</p>;
                }
            },
            {
                title: 'Transporteur',
                dataIndex: 'driver',
                key: 'driver',
                render: (driver) => {
                    if (driver === null) {
                        return;
                    }

                    return <Link to={'/driver/' + driver.id}>{driver.firstName} {driver.lastName}</Link>;
                }
            },
            {
                title: 'Statut',
                dataIndex: 'status',
                key: 'status',
                render: (status) => {
                    return (
                        <Tag color="green" key={status}>
                            {translateStatus[status]}
                        </Tag>
                    );
                }
            },
            {
                title: '',
                key: 'course',
                width: 250,
                render: (course) => {
                    return (
                        <div>
                            <Space wrap>
                                <Button type='primary' onClick={() => this.showDrawerCourseInfo(course.id)}>
                                    <EyeOutlined />
                                </Button>
                                <Button type='primary'>
                                    <Link to={'/course/' + course.uuid}><FormOutlined /></Link>
                                </Button>
                                <Button type='primary' onClick={() => this.onClickValidate(course.id)}>
                                    <CaretRightOutlined />
                                </Button>
                                <Button type='primary' onClick={() => this.onClickCanceled(course.id)}>
                                    <DeleteOutlined />
                                </Button>
                            </Space>
                        </div>
                    );
                }
            }
        ];

        this.fetchCourses = this.fetchCourses.bind(this);
        this.onClickValidate = this.onClickValidate.bind(this);
        this.onClickCanceled = this.onClickCanceled.bind(this);
        this.onClickExport = this.onClickExport.bind(this);
        this.onCloseCourseInfo = this.onCloseCourseInfo.bind(this);
        this.showDrawerCourseInfo = this.showDrawerCourseInfo.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showModalDriver = this.showModalDriver.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.handleAvailableCancel = this.handleAvailableCancel.bind(this);
        this.handleAvailableOk = this.handleAvailableOk.bind(this);
        this.setFilters = this.setFilters.bind(this);
    }

    /**
     *
     */
    componentDidMount() {
        const pagination = this.state.pagination;
        this.fetchCourses(pagination);
    }

    /**
     *
     */
    getPayload() {
        const filters = this.state.filters;

        let payload = {
            dateStart: filters.deliveryDates[0].format(dateFormat),
            dateEnd: filters.deliveryDates[1].format(dateFormat),
        };

        if (!filters.pickupTime.includes(null)) {
            payload.pickupTimeStart = filters.pickupTime[0].format(timeFormat);
            payload.pickupTimeEnd = filters.pickupTime[1].format(timeFormat);
        }
        if (!filters.deliveryTime.includes(null)) {
            payload.deliveryTimeStart = filters.deliveryTime[0].format(timeFormat);
            payload.deliveryTimeEnd = filters.deliveryTime[1].format(timeFormat);
        }
        if (filters.whiteLabelId) {
            payload.whiteLabelId = filters.whiteLabelId;
        }
        if (filters.status.length > 0) {
            payload.status = filters.status;
        }

        return payload;
    }

    /**
     *
     * @param {*} pagination (from Ant Table)
     * @param {*} filter (useless from Ant Table)
     * @param {*} sorter (useless from Ant Table)
     * @param {*} dataSource (useless from Ant Table)
     * @param {*} newFilter (if new filter, return page 1)
     */
    async fetchCourses(pagination, filter, sorter, dataSource, newFilter=false) {
        const page = (newFilter) ? 1 : pagination.current;
        const limit = pagination.pageSize;
        const payload = this.getPayload();

        await getCourses(page, limit, payload)
            .then((res) => res.json())
            .then((json) => {
                let coursesWithKey = json.courses;
                coursesWithKey.forEach((course) => {
                    course.key = course.id
                });

                this.setState({
                    loading: false,
                    courses: coursesWithKey,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     * @param {*} id 
     */
    onClickValidate(id) {
        let confirm = false
        courseValidate(id, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    const paginate = this.state.pagination;
                    this.fetchCourses(paginate);
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: data.previousPrice,
                        newPrice: data.newPrice,
                        uuid: data.course.uuid,
                        course: data.course
                    });
                }
            });
    }

    /**
     * 
     * @param {*} id 
     */
    onClickCanceled(id) {
        courseCanceled(id)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    uuid: data.uuid,
                    redirection: true
                });
            });
    }

    /**
     * 
     */
    onClickExport() {
        const filters = this.state.filters;
        const dateStartFilter =  filters.deliveryDates[0].format(dateFormat);
        const dateEndFilter = filters.deliveryDates[1].format(dateFormat);

        exportCourses(dateStartFilter, dateEndFilter)
            .then((res) => res.blob())
            .then(blob => saveAs(blob, 'export-courses.xls'));
    }

    /**
     *
     * @param {*} id
     */
    showDrawerCourseInfo(id) {
        this.setState({ visibleDrawerCourseInfo: true });

        getOneCourse(id)
            .then((res) => res.json())
            .then((json) => {
                this.setState({ course: json.course });
            });
    }

    /**
     *
     */
    onCloseCourseInfo() {
        this.setState({ visibleDrawerCourseInfo: false });
    }

    /**
     *
     * @param {*} value
     */
    showDrawer(value) {
        this.setState({ visibleDrawerCourseInfo: value });
    }

    /**
     *
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };


    /**
     *
     */
    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    /**
     *
     * @param {*} value
     */
    showModalDriver = (value) => {
        this.setState({ isModalVisible: value });
        this.setState({ selectedRowKeys: [] });
        const paginate = this.state.pagination;
        this.fetchCourses(paginate);
    }

    /**
     * 
     */
     handleAvailableOk() {
        const courseId = this.state.course.id;
        const confirm = true;

        courseValidate(courseId, confirm)
            .then((res) => res.json())
            .then((data) => {
                if (data.error === null) {
                    const paginate = this.state.pagination;
                    this.fetchCourses(paginate);
                } else {
                    this.setState({
                        modalAvailableVisible: true,
                        previousPrice: data.previousPrice,
                        newPrice: data.newPrice
                    });
                }
            });

        this.setState({ modalAvailableVisible: false });
    }

    /**
     * 
     */
    handleAvailableCancel() {
        this.setState({ modalAvailableVisible: false });
    }

    /**
     *
     * @param {*} values
     */
    setFilters(values) {
        this.setState({ filters: values }, () => {
            const pagination = this.state.pagination;
            this.fetchCourses(pagination, {}, {}, {}, true);
        });
    }

    render() {
        const { redirection } = this.state;
        const { uuid } = this.state;

        if (redirection) {
            return (<Redirect to={'/course/' + uuid} />);
        }

        const { pagination, loading } = this.state;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (course) =>({
                disabled: course.price === null
            })
        };
        const hasSelected = selectedRowKeys.length > 0;
        const user = this.context.global.profile;

        return (
            <Content>
                <div style={{ margin: '16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <PageHeader
                            className="site-page-header"
                            avatar={{ src: <Avatar icon={<UnorderedListOutlined />} /> }}
                            title="LISTE DES COURSES"
                            extra={[
                                <Link to="/course/add">
                                    <Button icon={<PlusCircleOutlined />}>Ajouter</Button>
                                </Link>,
                                <Button type="button" onClick={this.onClickExport} icon={<DownloadOutlined />}>
                                    Exporter
                                </Button>,
                            ]}
                        />
                        <Divider />
                        <Filter filters={defaultFilters} user={user} updateFilters={this.setFilters} />
                        <Divider />
                        <div style={{ marginBottom: 16 }}>
                            <Button type="primary" onClick={this.showModal} disabled={!hasSelected}>
                                Transporteur
                            </Button>
                            <ModalDriver
                                selectedRowKeys={selectedRowKeys}
                                showModalDriverVisible={this.state.isModalVisible}
                                showModalDriver={this.showModalDriver}
                            />
                        </div>
                        <Table
                            rowSelection={rowSelection}
                            columns={this.columns}
                            loading={loading}
                            onChange={this.fetchCourses}
                            pagination={{
                                ...pagination,
                                showSizeChanger: true,
                                pageSizeOptions: ["2", "20", "50"]
                            }}
                            dataSource={this.state.courses}
                        />
                        <DrawerDetailsCourse
                            course={this.state.course}
                            showDrawerCourseDetails={this.state.visibleDrawerCourseInfo}
                            showDrawer={this.showDrawer}
                        />
                    </div>
                </div>
                <Modal
                    visible={this.state.modalAvailableVisible}
                    onOk={this.handleAvailableOk}
                    onCancel={this.handleAvailableCancel}
                    cancelText="Annuler"
                >
                    <Alert 
                        showIcon 
                        message="Le prix de la course a changé." 
                        description={
                            <div>
                                <p>Ancien prix : {this.state.previousPrice} €</p>
                                <p>Nouveau prix : {this.state.newPrice} €</p>
                                <p>Confirmez-vous l'émission de la course avec le nouveau prix ?</p>
                            </div>
                        } 
                        type="warning" 
                        style={{ marginTop: '20px', marginBottom: '20px' }} 
                    />
                </Modal>
            </Content>
        );
    };
}

export default Courses;
