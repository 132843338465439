import React from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Layout, PageHeader, Space, Table} from 'antd';
import {FormOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {getManyWhiteLabels} from '../../Api/WhiteLabels.js'

class WhiteLabels extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            whiteLabels: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => {
                    return (
                        <Space size="middle">
                            <Button type='primary'>
                                <Link to={'/whiteLabel/' + id}><FormOutlined /> Editer</Link>
                            </Button>
                        </Space>);
                }
            }
        ]
    }

    /**
     * 
     * @param {*} params 
     */
    loadWhiteLabels(params = {}) {
        this.setState({ loading: true })
        getManyWhiteLabels(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    whiteLabels: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadWhiteLabels({
            pagination
        });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        this.loadWhiteLabels({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    render() {
        const { whiteLabels, pagination, loading } = this.state;
        const { Content } = Layout;
        return (
            <div>
                <PageHeader title="Marques blanches" subTitle="Liste des marques blanches" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to='/whitelabel/add'><PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter</Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider></Divider>
                        <Table columns={this.columns} loading={loading} onChange={this.handleTableChange} pagination={pagination} dataSource={whiteLabels} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default WhiteLabels;
