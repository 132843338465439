import React, {useEffect, useState} from 'react';
import {Avatar, Button, Col, DatePicker, Divider, Form, Layout, PageHeader, Radio, Row, Select, Space} from 'antd';
import {CalendarOutlined, SyncOutlined} from '@ant-design/icons';
import moment from 'moment';

import ResourcesTable from '../../Components/Course/ResourcesTable';
import DrawerDetailsCourse from '../../Components/Course/DrawerDetailsCourse';

import {getList as getCourses, getOneCourse} from '../../Api/Courses';
import {getManyMerchants} from '../../Api/Merchants';
import {getManyDrivers} from '../../Api/Drivers.js';
import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {roundDownHalfHour, roundUpHalfHour} from "../../utils";
import {colorsCourse, translateStatus} from "../../constants";
import {GlobalContext} from '../../GlobalContext';

const { Content } = Layout;
const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';
const layoutForm = {
    style: {
        paddingTop: 10,
    },
    labelAlign: 'left',
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
        style: {
            paddingRight: 20
        }
    },
};
const layoutFormItem = {
    style: {
        marginBottom: 20
    }
};

const defaultFilters = {
    deliveryDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    status: ['available', 'assigned', 'started', 'inProgress', 'pickupFailed', 'failed', 'isDelivered'],
    driverId: null,
    merchantId: null,
    whiteLabelId: null
};

/**
 *
 * @param {*} props (filters, user)
 * @returns
 */
const Filter = (props) => {
    const [form] = Form.useForm();
    const [merchants, setMerchants] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [whiteLabels, setWhiteLabels] = useState([]);

    const statusAllowed = [
        'available', 'assigned', 'started', 'inProgress', 'failed',
        'pickupFailed', 'isDelivered', 'canceled', 'returned'
    ];

    /**
     *
     * @param {*} value
     */
    const fetchMerchants = (payload) => {
        getManyMerchants(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        throw Error();
                    }
                    return res.json();
                })
                .then((json) => {
                    setMerchants(json.results);
                })
                .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const fetchDrivers = (payload) => {
        getManyDrivers(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        throw Error();
                    }
                    return res.json();
                })
                .then((json) => {
                    setDrivers(json.results);
                })
                .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const fetchWhiteLabels = (payload) => {
        getManyWhiteLabels(payload)
                .then((res) => {
                    if (res.status !== 200) {
                        throw Error();
                    }
                    return res.json();
                })
                .then((json) => {
                    setWhiteLabels(json.results);
                })
                .catch((err) => {});
    };

    /**
     *
     * @param {*} value
     */
    const onSearchMerchant = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value };
        fetchMerchants(payload);
    };

    /**
     *
     * @param {*} value
     */
    const onSearchDriver = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value, status: ['valid'] };
        fetchDrivers(payload);
    };

    /**
     *
     * @param {*} value
     */
    const onSearchWhiteLabel = (value) => {
        if (value.length <= 2) {
            return;
        }

        let payload = { term: value };
        fetchWhiteLabels(payload);
    };

    /**
     *
     * @param {*} values
     */
    const onFinish = (values) => {
        props.updateFilters(values);
    };

    /**
     *
     */
    const clearFilter = () => {
        form.setFieldsValue({ ...props.filters });
        props.updateFilters({ ...props.filters });
    };

    let itemWhiteLabel = null;
    if (props.user && !props.user.whiteLabel_id) {
        const whiteLabelOptions = whiteLabels.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>);
        itemWhiteLabel = (
            <Form.Item
                {...layoutFormItem}
                name="whiteLabelId"
                label="Marques blanches"
            >
                <Select
                    showSearch
                    showArrow
                    allowClear
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Entrer au minimum 3 caractères"
                    onSearch={onSearchWhiteLabel}
                >
                    {whiteLabelOptions}
                </Select>
            </Form.Item>
        );
    }

    const statusOptions = statusAllowed.map((d, index) => <Option key={index} value={d}>{translateStatus[d]}</Option>);
    const driverOptions = drivers.map((d) => <Option key={d.id} value={d.id}>{d.firstName} {d.lastName}</Option>);
    const merchantOptions = merchants.map((d) => <Option key={d.id} value={d.id}>{d.name}</Option>);

    return (
        <Form
            {...layoutForm}
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={props.filters}
        >
            <Row>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        label="Date de livraison le"
                        name="deliveryDate"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        name="merchantId"
                        label="Commerçant"
                    >
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            filterOption={false}
                            notFoundContent={null}
                            placeholder="Entrer au minimum 3 caractères"
                            onSearch={onSearchMerchant}
                        >
                            {merchantOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        {...layoutFormItem}
                        name="driverId"
                        label="Transporteur"
                    >
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            filterOption={false}
                            notFoundContent={null}
                            placeholder="Entrer au minimum 3 caractères"
                            onSearch={onSearchDriver}
                        >
                            {driverOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item {...layoutFormItem} name="status" label="Status des courses">
                        <Select
                            mode="multiple"
                            placeholder="Sélectionner un ou plusieurs statuts"
                        >
                            {statusOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {itemWhiteLabel}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Filtrer
                            </Button>
                            <Button onClick={clearFilter}>
                                Annuler
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

/**
 *
 * @param {*} props
 */
const Schedule = (props) => {
    const user = props.user;

    const resources = [{
        'type': 'merchant',
        'name': 'Commerçant',
    }, {
        'type': 'driver',
        'name': 'Transporteur',
    }];

    const [type, setType] = useState(resources[0].type);
    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState(null);
    const [showDrawerCourse, setShowDrawerCourse] = useState(false);
    const [filters, setFilters] = useState({ ...defaultFilters });

    const selectedDate = filters.deliveryDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const startTime = selectedDate.clone().set({ hour: 7, minute: 0 });
    const endTime = selectedDate.clone().set({ hour: 21, minute: 59 });

    /**
     *
     */
    const fecthCourses = () => {
        const payload = {
            dateStart: filters.deliveryDate.format(dateFormat),
            dateEnd: filters.deliveryDate.format(dateFormat),
            status: filters.status
        };

        if (filters.merchantId) {
            payload.merchantId = filters.merchantId;
        }
        if (filters.driverId) {
            payload.driverId = filters.driverId;
        }
        // if (user && !user.whiteLabel_id && filters.whiteLabelId) {
        if (filters.whiteLabelId) {
            payload.whiteLabelId = filters.whiteLabelId;
        }

        return getCourses(1, 50, payload)
            .then((res) => res.json())
            .then((json) => {
                setCourses(json.courses);
                setLoading(false);
            });
    };

    /**
     *
     * @param {*} courseId
     */
    const openDrawerCourse = (courseId) => {
        getOneCourse(courseId)
            .then((res) => res.json())
            .then((json) => {
                setCourse(json.course);
                setShowDrawerCourse(true);
            });
    };

    /**
     *
     * @param {*} merchantId
     */
    const redirectMerchant = (merchantId) => {
        return '/merchant/' + merchantId;
    }

    /**
     *
     */
    const closeDrawerCourse = () => {
        setCourse(null);
        setShowDrawerCourse(false);
    };

    /**
     *
     * @param {*} event
     */
    const formatEvent = (event) => {
        event.resourceId = event[type].id; // resource id for delivery schedule
        event.resourceName = (type !== "driver") ? event[type].name : event[type].commercialName; // resource name for delivery schedule
        event.color = colorsCourse[event.status];

        let collectDateTime = moment(event.deliveryDate + " " + event.collectTime, "YYYY-MM-D HH:mm");
        let deliveryDateTime = moment(event.deliveryDate + " " + event.deliveryTime, "YYYY-MM-DD HH:mm");
        event.startAt = roundDownHalfHour(collectDateTime);
        event.endAt = roundUpHalfHour(deliveryDateTime);

        return event;
    };

    /**
     *
     */
    const refresh = async () => {
        setLoading(true);
        await fecthCourses();
    };

    /**
     *
     * @param {*} values
     */
    const updateFilters = (values) => {
        setFilters({
            ...values,
            deliveryDate: values.deliveryDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        });
    };

    useEffect(() => {
        fecthCourses();
        // marche pas (trop de data a traiter)
        // const interval = setInterval(fecthCourses, 15000);
        // return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        refresh();
    }, [filters]);

    let events = courses
        .sort((a, b) => {
            if (a.created_at.date > b.created_at.date) return 1;
            if (a.created_at.date < b.created_at.date) return -1;
            return 0;
        })
        .filter(elem => elem[type] !== null)
        .map(event => formatEvent(event));

    const resourceName = resources.find(elem => elem.type === type).name;
    const radioButtons = resources.map((elem, index) => (
        <Radio.Button key={index} value={elem.type}>
            {elem.name}
        </Radio.Button>
    ));

    return (
        <Content>
            <div style={{ margin: '16px 16px' }}>
                <div className="site-layout-background" style={{ padding: '24px', minHeight: '360px' }}>
                    <PageHeader
                        className="site-page-header"
                        avatar={{ src: <Avatar icon={<CalendarOutlined />} /> }}
                        title="CALENDRIER DE LIVRAISION"
                        //subTitle="Mise à jour automatique toutes les 15s"
                    />
                    <Divider />
                    <Filter
                        resourceType={type}
                        filters={defaultFilters}
                        user={user}
                        updateFilters={updateFilters}
                    />
                    <Divider />
                    <Space size="large" style={{ padding: 20, paddingLeft: 0 }}>
                        <div>
                            Trier par :
                            <Radio.Group
                                buttonStyle="solid"
                                defaultValue={resources[0].type}
                                onChange={e => setType(e.target.value)}
                                style={{ paddingLeft: 8 }}
                            >
                                {radioButtons}
                            </Radio.Group>
                        </div>
                        <Button onClick={refresh}><SyncOutlined spin={loading} />Rafraîchir</Button>
                    </Space>
                    <ResourcesTable
                        dateDisplay={selectedDate}
                        startTimeDisplay={startTime}
                        endTimeDisplay={endTime}
                        resourceType={type}
                        resourceName={resourceName}
                        events={events}
                        loading={loading}
                        resourcePath={redirectMerchant}
                        onClickEvent={openDrawerCourse}
                    />
                </div>
            </div>
            <DrawerDetailsCourse
                course={course}
                showDrawerCourseDetails={showDrawerCourse}
                showDrawer={closeDrawerCourse}
            />
        </Content>
    );
};

/**
 *
 * @param {*} props
 */
class Progress extends React.Component {
    static contextType = GlobalContext;

    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
    }

    render() {
        const user = this.context.global.profile;
        return(<Schedule user={user} />);
    }
};

export default Progress;
