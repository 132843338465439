import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import './App.less';
import Login from './Pages/Login/Login.js';
import Dashboard from './Pages/Dashboard/Dashboard.js';
import MapDispatch from './Pages/MapDispatch/MapDispatch.js';
import Schedule from './Pages/Schedule/Schedule.js';
import WhiteLabels from './Pages/Whitelabel/Whitelabels.js';
import WhiteLabelAdd from './Pages/Whitelabel/WhitelabelAdd.js';
import WhiteLabelEdit from './Pages/Whitelabel/WhitelabelEdit.js';
import Networks from './Pages/Network/Networks.js';
import NetworkAdd from './Pages/Network/NetworkAdd.js';
import NetworkEdit from './Pages/Network/NetworkEdit.js';
import MerchantThemes from './Pages/MerchantTheme/MerchantThemes.js';
import MerchantThemeAdd from './Pages/MerchantTheme/MerchantThemeAdd.js';
import MerchantThemeEdit from './Pages/MerchantTheme/MerchantThemeEdit.js';
import Merchants from './Pages/Merchant/Merchants.js';
import MerchantAdd from './Pages/Merchant/MerchantAdd.js';
import MerchantEdit from './Pages/Merchant/MerchantEdit.js';
import Customers from './Pages/Customer/Customers.js';
import CustomerAdd from './Pages/Customer/CustomerAdd.js';
import CustomerEdit from './Pages/Customer/CustomerEdit.js';
import Drivers from './Pages/Driver/Drivers.js';
import DriverAdd from './Pages/Driver/DriverAdd.js';
import DriverEdit from './Pages/Driver/DriverEdit.js';
import Courses from './Pages/Course/Courses.js';
import CourseAdd from './Pages/Course/CourseAdd.js';
import CourseEdit from './Pages/Course/CourseEdit.js';
import CourseImport from './Pages/Course/CourseImport.js';
import SettingsMerchant from './Pages/SettingsMerchant/SettingsMerchant.js';
import AppLayout from './AppLayout.js';
import UserProvider, { UserContext } from "./UserContext.js";
import { GlobalContext } from './GlobalContext';
import Tours from "./Pages/Tour/Tours";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, ...rest }) {
  let auth = localStorage.getItem('token');
  if (auth !== null) {
    const jwt_Token_decoded = jwt_decode(auth);
    const expirationTime = (jwt_Token_decoded.exp * 1000) - 60000
    try {
      if (Date.now() >= expirationTime) {
        auth = ''
      }
    } catch (e) {
      auth = ''
    }
  }
  
  // Requis pour le context global
  const [global, updateGlobal] = useState({
    'profile': null,
    'config': null
  });
  return (
    <Route
      {...rest}
      render={(props) => {
        let state = {
          'global': global,
          'updateGlobal': updateGlobal
        }

        return (
          auth !== null && auth !== '' ? (

            <GlobalContext.Provider value={state}><AppLayout ><Component {...props} /></AppLayout></GlobalContext.Provider>

          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        )
      }
      }
    />
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/' component={Dashboard} />
          <PrivateRoute exact path='/map' component={MapDispatch} />
          <PrivateRoute exact path='/courses/schedule' component={Schedule} />
          <PrivateRoute exact path='/settingsmerchant' component={SettingsMerchant} />
          <PrivateRoute exact path='/courses' component={Courses} />
          <PrivateRoute exact path='/course/add' component={CourseAdd} />
          <PrivateRoute exact path='/course/:id' component={CourseEdit} />
          <PrivateRoute exact path='/courses/importer' component={CourseImport} />
          <PrivateRoute exact path='/drivers' component={Drivers} />
          <PrivateRoute exact path='/driver/add' component={DriverAdd} />
          <PrivateRoute exact path='/driver/:id' component={DriverEdit} />
          <PrivateRoute exact path='/customers' component={Customers} />
          <PrivateRoute exact path='/customer/add' component={CustomerAdd} />
          <PrivateRoute exact path='/customer/:id' component={CustomerEdit} />
          <PrivateRoute exact path='/tours' component={Tours} />
          <PrivateRoute exact path='/merchants' component={Merchants} />
          <PrivateRoute exact path='/merchant/add' component={MerchantAdd} />
          <PrivateRoute exact path='/merchant/:id' component={MerchantEdit} />
          <PrivateRoute exact path='/whitelabels' component={WhiteLabels} />
          <PrivateRoute exact path='/whitelabel/add' component={WhiteLabelAdd} />
          <PrivateRoute exact path='/whitelabel/:id' component={WhiteLabelEdit} />
          <PrivateRoute exact path='/networks' component={Networks} />
          <PrivateRoute exact path='/network/add' component={NetworkAdd} />
          <PrivateRoute exact path='/network/:id' component={NetworkEdit} />
          <PrivateRoute exact path='/merchantthemes' component={MerchantThemes} />
          <PrivateRoute exact path='/merchanttheme/add' component={MerchantThemeAdd} />
          <PrivateRoute exact path='/merchanttheme/:id' component={MerchantThemeEdit} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;
