import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Form, Input, Space} from 'antd';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormWhitelabel = (props) => {

    let { whitelabel } = props;

    const [form] = Form.useForm();

    /**
     * 
     */
    useEffect(() => {
        if (whitelabel !== null) {
            let formValues = {
                name: whitelabel.name,
            }

            form.setFieldsValue(formValues);
        }
    })

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        props.formSubmit(values);
    };

    return (
        <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Form.Item label="Nom" name="name" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer une marque blanche' }]}>
                <Input name="name" />
            </Form.Item>
            <Divider></Divider>
            <Form.Item {...tailLayout}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/whitelabels'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormWhitelabel;
