import React from 'react';
import { getUser } from './Api/Login';

export const UserContext = React.createContext({
    user: null,
});

class UserProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {}, // une valeur de départ
        };
    }
    
    async loadUser() {
        await getUser()
            .then((res) => res.json())
            .then((data) =>
                this.setState({ user: data.user })
            )
    }

    async componentDidMount() {
        this.loadUser();
    }

    render() {
        return (
            /**
             * la propriété value est très importante ici, elle rend
             * le contenu du state disponible aux `Consumers` de l'application
             */
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
  
export default UserProvider;