import React from 'react';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {getOneCustomer, updateCustomer} from '../../Api/Customers.js';
import FormCustomer from './FormCustomer.js';

class CustomerEdit extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            customer: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     */
    loadCustomer() {
        const id = this.props.match.params.id;
        getOneCustomer(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({
                    customer: data.customer
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadCustomer();
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        const customerId = this.props.match.params.id;
        let data = { ...values };
        data.id = customerId;
        updateCustomer(customerId, data)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le client a bien été mis à jour'
                })
                this.setState({
                    customer: data.customer
                });
            })
    };

    render() {
        const { Content } = Layout;

        return (
            <div>
                <PageHeader title="Clients" subTitle="Edition d'un client" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormCustomer customer={this.state.customer} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>

        )
    }
}

export default CustomerEdit;
