import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {Button, Divider, Input, Layout, PageHeader, Popconfirm, Select, Space, Table} from 'antd';
import {CloseOutlined, FormOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {deleteMerchant, getManyMerchants} from '../../Api/Merchants.js';
import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {getManyNetworks} from '../../Api/Networks.js';
import {GlobalContext} from '../../GlobalContext.js';

class Merchants extends React.Component {
    static contextType = GlobalContext;
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            merchants: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            dataWhiteLabel: [],
            whiteLabel_id: null,
            dataNetwork: [],
            network_id: null,
            merchant_name: null,
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50,
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: 'Contact Principal',
                key: 'ownerFirstName',
                render: (row) => (
                    <div>{row.ownerFirstName} {row.ownerLastName}</div>
                )
            },
            {
                title: 'Adresse',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Date de création',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (created_at) => (
                    <div>{moment(created_at.date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}</div>
                )
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 270,
                render: (id) => (
                    <Space size="middle" wrap>
                        <Button type='primary' className='button-table'>
                            <Link to={'/merchant/' + id}><FormOutlined /> Editer</Link>
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir supprimer ce commerçant ?"
                            onConfirm={(e) => this.confirmDeleteMerchant(id, e)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button type='primary' danger className='button-table'>
                                <CloseOutlined /> Supprimer
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            },
        ];

        this.handleSearchWhiteLabel = this.handleSearchWhiteLabel.bind(this);
        this.onClickFilterMerchants = this.onClickFilterMerchants.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.handleSearchNetwork = this.handleSearchNetwork.bind(this);
        this.onChangeNetwork = this.onChangeNetwork.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.confirmDeleteMerchant = this.confirmDeleteMerchant.bind(this);
        this.onSearchMerchantName = this.onSearchMerchantName.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadMerchants(params = {}) {
        this.setState({ loading: true })
        getManyMerchants(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    merchants: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                })
            })
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadMerchants({ pagination });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        let data = {
            whiteLabel_id: this.state.whiteLabel_id,
            network_id: this.state.network_id,
            term: this.state.merchant_name,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        }
        getManyMerchants(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    merchants: json.results,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: json.totalCount,
                    },
                })
            })
    };

    /**
     * 
     */
     onClickFilterMerchants() {
        const pagination = this.state.pagination;
        let whiteLabel_id = this.state.whiteLabel_id;
        let network_id = this.state.network_id;
        let merchant_name = this.state.merchant_name;
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            network_id: network_id,
            term: merchant_name
        }
        getManyMerchants(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    merchants: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            whiteLabel_id: null,
            network_id: null,
            merchant_name: null
        })
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadMerchants({ pagination });
    }

    /**
     *
     * @param {*} value
     */
     handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataWhiteLabel: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ whiteLabel_id: value })
    }

    /**
     *
     * @param {*} value
     */
     handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataNetwork: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeNetwork(value) {
        this.setState({ network_id: value })
    }

    /**
     *
     * @param {*} e
     */
     onSearchMerchantName = (e) => {
        if (e.target.value.length > 2) {
            this.setState({ merchant_name : e.target.value})
        }
    };


    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    confirmDeleteMerchant(id, e) {
        e.preventDefault();
        deleteMerchant(id)
            .then((res) => res.json())
            .then(() => {
                const { pagination } = this.state;
                this.loadMerchants({ pagination });
            })
    }

    render() {
        const { merchants, pagination, loading } = this.state;
        const { Content } = Layout;
        const { Option } = Select;

        const user = this.context.global.profile;
        
        let filterWhiteLabel = null;
        if (user !== null && user.whiteLabel_id === null ) {
            filterWhiteLabel = (
                <div>
                    <Space>
                        <div className="filter-title">Marques blanches </div>
                        <Select
                            className="filter"
                            showSearch
                            value={this.state.whiteLabel_id}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={this.handleSearchWhiteLabel}
                            onChange={this.onChangeWhiteLabel}
                            notFoundContent={null}
                        >
                            {this.state.dataWhiteLabel.map((d) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Space>
                </div>  
            )
        }

        return (
            <div>
                <PageHeader title="Commerçants" subTitle="Liste des commerçants" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to='/merchant/add'><PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter</Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider></Divider>
                        <Space direction="vertical">
                            {filterWhiteLabel}
                            <Space>
                                <div className="filter-title">Réseaux </div>
                                <Select
                                    className="filter"
                                    showSearch
                                    value={this.state.network_id}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchNetwork}
                                    onChange={this.onChangeNetwork}
                                    notFoundContent={null}
                                >
                                    {this.state.dataNetwork.map((d) => (
                                        <Option key={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space>
                                <div className="filter-title">Nom </div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchMerchantName}
                                    className="filter"
                                />
                            </Space>
                            <Space>
                                <Button className="filter-button" type="primary" onClick={this.onClickFilterMerchants}>
                                    Filtrer
                                </Button>
                                <Button style={{ backgroundcolor: "#2FAC66" }} onClick={this.onClickDeleteFilter}>
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        
                        <Divider></Divider>
                        <Table 
                            columns={this.columns} 
                            loading={loading} 
                            onChange={this.handleTableChange} 
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}  
                            dataSource={merchants} 
                        />
                    </div>
                </Content>
            </div>
        )
    }
}

export default Merchants;
