import React from 'react';
import {Link} from 'react-router-dom';

import {Button, Divider, Input, Layout, PageHeader, Popconfirm, Select, Space, Table} from 'antd';
import {FormOutlined, InboxOutlined, PlusCircleOutlined, UnorderedListOutlined} from '@ant-design/icons';

import {archive as archiveCustomer, deleteCustomer, getManyCustomers} from '../../Api/Customers.js';
import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {GlobalContext} from '../../GlobalContext.js';
import {getManyMerchants} from '../../Api/Merchants.js';

class Customers extends React.Component {

    static contextType = GlobalContext;

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            dataWhiteLabel: [],
            whiteLabel_id: null,
            merchant_id: null,
            dataMerchant: [],
            customer_name: null
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Nom',
                dataIndex: 'name',
                sorter: true,
                key: 'name',
            },
            {
                title: 'Adresse',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type='primary'>
                            <Link to={'/customer/' + id}><FormOutlined /> Editer</Link>
                        </Button>
                        <Popconfirm
                            title="Etes-vous sûr de vouloir archiver ce client ?"
                            onConfirm={(e) => this.confirmArchiveCustomer(id, e)}
                            okText="Oui"
                            cancelText="Non"
                            placement="topRight"
                        >
                            <Button type='primary' danger className='button-table'>
                                <InboxOutlined /> Archiver
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            }
        ];

        this.handleSearchWhiteLabel = this.handleSearchWhiteLabel.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.handleSearchMerchant = this.handleSearchMerchant.bind(this);
        this.handleChangeMerchant = this.handleChangeMerchant.bind(this);
        this.onSearchCustomerName = this.onSearchCustomerName.bind(this);
        this.onClickFilterCustomers = this.onClickFilterCustomers.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.confirmDeleteCustomer = this.confirmDeleteCustomer.bind(this);
        this.confirmArchiveCustomer = this.confirmArchiveCustomer.bind(this);
    }

    /**
     * 
     * @param {*} params 
     */
    loadCustomers(params = {}) {
        this.setState({ loading: true })
        getManyCustomers(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    customers: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    componentDidMount() {
        const { pagination } = this.state;
        this.loadCustomers({ pagination });
    }

    /**
     * 
     * @param {*} pagination 
     * @param {*} filters 
     * @param {*} sorter 
     */
    handleTableChange = (pagination, filters, sorter) => {
        this.loadCustomers({
            whiteLabel_id: this.state.whiteLabel_id,
            merchant_id: this.state.merchant_id,
            term: this.state.customer_name,
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };

    /**
     * 
     */
     onClickFilterCustomers() {
        const pagination = this.state.pagination;
        let whiteLabel_id = this.state.whiteLabel_id;
        let merchant_id = this.state.merchant_id;
        let customer_name = this.state.customer_name;
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            merchant_id: merchant_id,
            term : customer_name
        };

        getManyCustomers(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    customers: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     * 
     */
    onClickDeleteFilter() {
        this.setState({
            whiteLabel_id: null,
            merchant_id: null,
            customer_name : null
        });
        const pagination = {
            current: 1,
            pageSize: 10
        };
        this.loadCustomers({ pagination });
    }

    /**
     *
     * @param {*} value
     */
    handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataWhiteLabel: json.results });
                });
        }
    };

    /**
     * 
     * @param {*} value 
     */
    onChangeWhiteLabel(value) {
        this.setState({ whiteLabel_id: value });
    }

        /**
     * 
     * @param {*} value 
     */
    handleSearchMerchant(value) {
        if (value.length > 2) {
            let data = {
                term: value
            };
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({ dataMerchant: json.results });
                });
        }
    }
    
    /**
     * 
     * @param {*} values 
     */
    handleChangeMerchant (values) {
        this.setState({ merchant_id: values});
    }

    /**
     *
     * @param {*} e
     */
    onSearchCustomerName(e) {
        if (e.target.value.length > 2) {
            this.setState({ customer_name : e.target.value });
        }
    }

    /**
     * 
     * @param {*} id 
     * @param {*} e 
     */
    confirmDeleteCustomer(id, e) {
        e.preventDefault();
        deleteCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                this.loadCustomers({ pagination });
            });
    }

    /**
     *
     * @param {*} id
     * @param {*} e
     */
    confirmArchiveCustomer(id, e) {
        e.preventDefault();
        archiveCustomer(id)
            .then((res) => res.json())
            .then(() => {
                const pagination = {
                    current: 1,
                    pageSize: 10
                };
                this.loadCustomers({ pagination });
            });
    }

    render() {
        const { Content } = Layout;
        const { Option } = Select;
        const { customers, pagination, loading } = this.state;

        const user = this.context.global.profile;
        
        let filterWhiteLabel = null;
        if (user !== null && user.whiteLabel_id === null ) {
            filterWhiteLabel = (
                <div>
                    <Space>
                        <div className="filter-title">Marques blanches </div>
                        <Select
                            className="filter"
                            showSearch
                            value={this.state.whiteLabel_id}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={this.handleSearchWhiteLabel}
                            onChange={this.onChangeWhiteLabel}
                            notFoundContent={null}
                            allowClear
                        >
                            {this.state.dataWhiteLabel.map((d) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Space>
                </div>
            );
        }

        return (
            <div>
                <PageHeader title="Clients" subTitle="Liste des clients" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> LISTE</h2>
                        <div className="button-add">
                            <Space>
                                <Button>
                                    <Link to='/customer/add'>
                                        <PlusCircleOutlined style={{ paddingRight: "5px" }} /> Ajouter
                                    </Link>
                                </Button>
                            </Space>
                        </div>
                        <Divider />
                        <Space direction="vertical">
                            {filterWhiteLabel}
                            <Space>
                                <div className="filter-title">Commerçant</div>
                                <Select
                                    className="filter"
                                    showSearch
                                    value={this.state.merchant_id}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchMerchant}
                                    onChange={this.handleChangeMerchant}
                                    notFoundContent={null}
                                    allowClear
                                >
                                    {this.state.dataMerchant.map((d) => (
                                        <Option key={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space>
                                <div className="filter-title">Nom</div>
                                <Input
                                    placeholder="Entrer au minimum 3 caractères"
                                    allowClear
                                    enterButton={false}
                                    onChange={this.onSearchCustomerName}
                                    className="filter"
                                />
                            </Space>
                            <Space>
                                <Button
                                    className="filter-button"
                                    type="primary"
                                    onClick={this.onClickFilterCustomers}
                                >
                                    Filtrer
                                </Button>
                                <Button
                                    style={{ backgroundcolor: "#2FAC66" }}
                                    onClick={this.onClickDeleteFilter}
                                >
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider />
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={customers}
                        />
                    </div>
                </Content>
            </div>
        );
    }
}

export default Customers;
