import React from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {postCustomer} from '../../Api/Customers.js';
import FormCustomer from './FormCustomer.js';

class CustomerAdd extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            value: 1,
            redirection: false,
            id: null,
            customer: null,
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        postCustomer(values)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'Le client a bien été enregistré'
                })
                this.setState({
                    id: data.customer.id,
                    redirection: true
                });
            });
    };

    render() {
        const { Content } = Layout;
        const { redirection } = this.state;
        const { id } = this.state;

        if (redirection) {
            return (<Redirect to={'/customer/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Clients" subTitle="Création d'un client" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormCustomer customer={this.state.customer} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>

        )
    }
}

export default CustomerAdd;
