import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';

import {Button, Divider, Form, Input, Select, Space} from 'antd';

import {getManyMerchants} from '../../Api/Merchants.js';

const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const { Option } = Select;
const { TextArea } = Input;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormCustomer = (props) => {

    const { customer } = props;

    const [form] = Form.useForm();

    const [location, setLocation] = React.useState(null);
    const [valueMerchant, setValueMerchant] = React.useState(undefined);
    let [dataMerchant, setDataMerchant] = React.useState([]);
    const optionMerchant = dataMerchant.map(d => <Option key={d.id}>{d.name}</Option>);


    /**
     * 
     * @param {*} location 
     */
    const onChangeLocation = (location) => {
        setLocation(location);
        geocodeByAddress(location.label)
            .then(results => {
                let zipCode = "";
                let addressResults = results[0].address_components;
                addressResults.map(addressResult => {
                    let types = addressResult.types;
                    types.map(type => {
                        if (type === "postal_code") {
                            zipCode = addressResult.long_name
                        }
                    })
                })
                form.setFieldsValue({
                    customer_address: location.label,
                    customer_zip_code: zipCode
                });
            });
    };

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newCustomer = {
            name: values.customer_name,
            firstName: values.customer_firstName,
            phone: values.customer_phone,
            mobilePhone: values.customer_mobilePhone,
            email: values.customer_email,
            floor: values.customer_floor,
            intercomCode: values.customer_intercomCode,
            merchant_id: values.customer_merchantId.value,
            comment: values.customer_comment,
            zip_code: values.customer_zip_code,
        };

        if (location === null) {
            newCustomer.address = values.address;
            props.formSubmit(newCustomer);
            return;
        }

        geocodeByAddress(location.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                newCustomer.address = location.label;
                newCustomer.longitude = lng;
                newCustomer.latitude = lat;

                props.formSubmit(newCustomer);
            });
    };

    /**
    * 
    * @param {*} value 
    */
    const handleSearchMerchant = (value) => {
        if (value.length > 2) {
            let data = {
                term: value
            };
            getManyMerchants(data)
                .then((res) => res.json())
                .then((json) => {
                    setDataMerchant(dataMerchant = json.results);
                });
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeMerchant = (values) => {
        setValueMerchant(values.value);
    }

    /**
     * 
     */
    useEffect(() => {
        if (customer !== null) {
            const { merchant=null } = customer;

            let formValues = {
                customer_name: customer.name,
                customer_address: customer.address,
                customer_longitude: customer.longitude,
                customer_latitude: customer.latitude,
                customer_firstName: customer.firstname,
                customer_phone: customer.phone,
                customer_mobilePhone: customer.mobilePhone,
                customer_email: customer.email,
                customer_floor: customer.floor,
                customer_intercomCode: customer.intercomCode,
                customer_comment: customer.comment,
                customer_zip_code: customer.zip_code,
                customer_merchantId: {
                    key: (merchant) ? merchant.id : null,
                    value: (merchant) ? merchant.id : null,
                    label: (merchant) ? merchant.name : null,
                }
            };

            form.setFieldsValue(formValues);

            setLocation({
                "label": formValues.customer_address,
                "value": {}
            });
        }
    }, [customer]);

    return (
        <Form
            {...layout}
            form={form}
            name="layout"
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item
                label="Commerçant"
                name="customer_merchantId"
                hasFeedback
                rules={[{
                    required: true,
                    message: 'Merci de choisir un commerçant.'
                }]}
            >
                <Select
                    labelInValue={true}
                    showSearch
                    value={valueMerchant}
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearchMerchant}
                    onChange={handleChangeMerchant}
                    notFoundContent={null}
                    allowClear
                >
                    {optionMerchant}
                </Select>
            </Form.Item>
            <Form.Item
                label="Nom"
                name="customer_name"
                hasFeedback
                rules={[{
                    required: true,
                    message: 'Merci d\'indiquer un nom.'
                }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Prénom"
                name="customer_firstName"
                hasFeedback
                rules={[{
                    required: true,
                    message: 'Merci d\'indiquer un prénom.'
                }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Email" name="customer_email" >
                <Input />
            </Form.Item>
            <Form.Item label="Adresse" name="customer_address">
                <GooglePlacesAutocomplete
                    apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                    selectProps={{
                        value: location,
                        onChange: onChangeLocation,
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['fr'],
                        }
                    }}
                />
            </Form.Item>
            <Form.Item label="Code Postal" name="customer_zip_code">
                <Input />
            </Form.Item>
            <Form.Item label="Téléphone" name="customer_phone">
                <Input />
            </Form.Item>
            <Form.Item label="Téléphone mobile" name="customer_mobilePhone">
                <Input />
            </Form.Item>
            <Form.Item label="Etage" name="customer_floor">
                <Input />
            </Form.Item>
            <Form.Item label="Interphone" name="customer_intercomCode">
                <Input />
            </Form.Item>
            <Form.Item label="Commentaires" name="customer_comment">
                <TextArea rows={12} />
            </Form.Item>
            <Divider />
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: "#2FAC66" }}>
                        <Link to="/customers">Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormCustomer;
