import React from 'react';

import {Alert, Button, Divider, Layout, message, PageHeader, Space, Upload} from 'antd';
import {UnorderedListOutlined, UploadOutlined} from '@ant-design/icons';
import {importCourses} from '../../Api/Courses';

import config from '../../Config.js';

class CourseImport extends React.Component {
    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            fileList: [],
            uploading: false,
            errors: false,
        }

        this.uploadMedia = this.uploadMedia.bind(this);
    }

    /**
     * 
     * @param {*} file 
     */
    uploadMedia = () => {

        const formData = new FormData();
        formData.append('document', this.state.fileList[0]);

        importCourses(formData)
            .then(response => response.json())
            .then(data => {
                data.map(d => {
                    if (d === null) {
                        this.setState({
                            fileList: [],
                            uploading: false,
                        });
                        message.success("Votre fichier a bien été importé.");
                    } else {
                        this.setState({ 
                            errors: true,
                            fileList: [],
                            uploading: false,
                        });
                        message.error("Votre fichier comporte des erreurs.");
                    }
                })
            })
    }

    render() {
        const { Content } = Layout;
        const { uploading, fileList } = this.state;

        let templateFilePath = config.hostApp + '/import_courses_exemple.csv';
        let errorFilePath =  config.hostApp + '/courses/downloadimporterrrors'

        let errors = this.state.errors;
        let errorAlert = null
        if (errors === true) {
            errorAlert = (
                <Alert 
                    showIcon 
                    type="warning"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    message={
                        <div>
                            <p> L'import a généré des erreurs. Toutes les courses n'ont pas pu êtres importées :</p>
                            <a href={errorFilePath}>Télécharger les courses avec erreurs</a>
                        </div>
                    } 
                />
            )
        }

        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if(this.state.fileList.length===0){
                    this.setState(state => ({
                      fileList: [...state.fileList, file],
                    }));
                  }else{
                    message.error('Vous ne pouvez importer qu\'un seul fichier.');
                    this.setState(state => ({
                      fileList: [...state.fileList],
                    }));
                  }
                return false;
            },
            fileList,
        };

        return (
            <div>
                <PageHeader title="Courses" subTitle="Importer des courses" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><UnorderedListOutlined /> FICHIER</h2>
                        <Divider></Divider>
                        <Alert
                            description="L'import de courses par fichier est brut. Aucune vérification des données, des distances, 
                                des tarifs n'est faite par rapport à un quelconque paramètrage de Network. Le fichier d'import doit 
                                respecter le format du fichier ci-dessous."
                            type="info"
                            showIcon
                        />
                        <Space className="download-exemple">
                            <div>
                                <a href={templateFilePath} download="import_courses_exemple.csv">Télécharger le modèle du fichier </a>
                            </div>
                        </Space>
                        <div className="download-form" style={{ textAlign: 'center' }}>
                            Fichier* (.csv)
                            <Upload {...props} >
                                <Button icon={<UploadOutlined />}>Choisissez un fichier</Button>
                            </Upload>
                            <Button
                                type="primary"
                                onClick={this.uploadMedia}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{ marginTop: 16 }}
                            >
                                {uploading ? 'Uploading' : 'Importer'}
                            </Button>
                        </div>
                        {errorAlert}
                    </div>
                </Content>
            </div>

        )
    }
}

export default CourseImport;
