import React from 'react';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {getOneWhiteLabel, updateWhiteLabel} from '../../Api/WhiteLabels.js';
import FormWhitelabel from './FormWhitelabel.js';

class whiteLabelEdit extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            whitelabel: null
        }

        this.onFinish = this.onFinish.bind(this);
    }

    /**
     * 
     */
    loadwhiteLabel() {
        const id = this.props.match.params.id;
        getOneWhiteLabel(id)
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({ whitelabel: data.whiteLabel });
            })
    }

    /**
     * 
     */
    componentDidMount() {
        this.loadwhiteLabel();
    }

    /**
     * 
     * @param {*} values 
     */
    onFinish(values) {
        const whiteLabelId = this.props.match.params.id;
        let data = { ...values };
        data.id = whiteLabelId;
        updateWhiteLabel(whiteLabelId, data)
            .then(function (response) {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'La marque Blanche a bien été mise à jour'
                })
                this.setState({
                    whitelabel: data.whiteLabel
                });
            })
    };

    render() {
        const { Content } = Layout;

        return (
            <div>
                <PageHeader title="Marques blanches" subTitle="Edition d'une marque blanche" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormWhitelabel whitelabel={this.state.whitelabel} formSubmit={this.onFinish} />
                    </div>
                </Content>
            </div>
        )
    }
}

export default whiteLabelEdit;
