import React from 'react';
import {Redirect} from 'react-router-dom';

import {Divider, Layout, notification, PageHeader} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

import {postWhiteLabel} from '../../Api/WhiteLabels.js'
import FormWhitelabel from './FormWhitelabel.js';

class WhiteLabelAdd extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            redirection: false,
            id: null,
            whitelabel: null
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * 
     * @param {*} values 
     */
    onSubmit(values) {
        postWhiteLabel(values)
            .then((response) => {
                return response.json();
            }).then((data) => {
                notification['success']({
                    message: 'La marque Blanche a bien été enregistrée'
                })
                this.setState({
                    id: data.whiteLabel.id,
                    redirection: true
                });
            });
    };

    render() {
        const { redirection } = this.state;
        const { id } = this.state;
        const { Content } = Layout;

        if (redirection) {
            return (<Redirect to={'/whitelabel/' + id} />)
        }

        return (
            <div>
                <PageHeader title="Marques blanches" subTitle="Création d'une marque blanche" />
                <Content style={{ margin: '0 16px 16px 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <h2 style={{ color: "#2FAC66" }}><InfoCircleOutlined /> INFORMATIONS</h2>
                        <Divider></Divider>
                        <FormWhitelabel whitelabel={this.state.whitelabel} formSubmit={this.onSubmit} />
                    </div>
                </Content>
            </div>
        );
    }
}

export default WhiteLabelAdd;
