import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Layout,
    PageHeader,
    Progress,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip
} from 'antd';
import {AimOutlined, EyeOutlined, HomeOutlined, MoreOutlined, UnorderedListOutlined, ArrowDownOutlined} from '@ant-design/icons';

import {getManyWhiteLabels} from "../../Api/WhiteLabels.js";
import {GlobalContext} from '../../GlobalContext.js';
import {getManyTours, getOneTour, postTourCoursesOrder} from "../../Api/Tours";
import {getManyNetworks} from "../../Api/Networks";
import DrawerDetailsCourse from "../../Components/Course/DrawerDetailsCourse";
import {getOneCourse} from "../../Api/Courses";
import {closestCorners, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';
import {translateStatus} from "../../constants";

class Tours extends React.Component {

    static contextType = GlobalContext;

    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.state = {
            tours: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            dataWhiteLabel: [],
            whiteLabel_id: null,
            network_id: null,
            customer_name: null,
            dataNetwork: [],
            date: moment(),
            showDrawerTour: false,
            selectedTourId: null,
            selectedCourse: null,
            showDrawerCourse: false,
        };

        this.columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50
            },
            {
                title: 'Réseau',
                dataIndex: 'network',
                sorter: true,
                key: 'driver',
                render: (network) => (
                    <Link to={'/network/' + network.id}>{network.name}</Link>
                )
            },
            {
                title: 'Transporteur',
                dataIndex: 'driver',
                sorter: true,
                key: 'driver',
                render: (item) => (
                    <Link to={'/driver/' + item.id}>{item.firstName} {item.lastName}</Link>
                )
            },
            {
                title: 'Progression',
                key: 'progression',
                render: (tour) => {
                    return (
                        <TourProgress tour={tour}/>
                    )
                }
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (id) => (
                    <Space size="middle">
                        <Button type='primary' onClick={() => {
                            this.setState({showDrawerTour: true, selectedTourId: id})
                        }}><EyeOutlined/>
                        </Button>
                    </Space>
                )
            }
        ];

        this.handleSearchWhiteLabel = this.handleSearchWhiteLabel.bind(this);
        this.onChangeWhiteLabel = this.onChangeWhiteLabel.bind(this);
        this.handleSearchNetwork = this.handleSearchNetwork.bind(this);
        this.onClickFilterTours = this.onClickFilterTours.bind(this);
        this.onClickDeleteFilter = this.onClickDeleteFilter.bind(this);
        this.onChangeNetwork = this.onChangeNetwork.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.showSelectedCourse = this.showSelectedCourse.bind(this);
    }

    /**
     *
     * @param {*} params
     */
    loadTours(params = {}) {
        this.setState({loading: true})
        getManyTours(params)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    loading: false,
                    tours: json.results,
                    pagination: {
                        ...params.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     */
    componentDidMount() {
        const {pagination} = this.state;
        this.loadTours({
            whiteLabel_id: this.state.whiteLabel_id,
            network_id: this.state.network_id,
            date: this.state.date.format('Y-MM-DD'),
            pagination,
        });
    }

    /**
     *
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        this.loadTours({
            whiteLabel_id: this.state.whiteLabel_id,
            network_id: this.state.network_id,
            date: this.state.date.format('Y-MM-DD'),
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
        });
    };


    /**
     *
     * @param {*} value
     */
    handleSearchNetwork = (value) => {
        if (value.length > 2) {
            getManyNetworks({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({dataNetwork: json.results});
                });
        }
    };

    /**
     *
     * @param {*} value
     */
    onChangeNetwork(value) {
        this.setState({network_id: value})
    }

    /**
     *
     * @param {*} value
     */
    onChangeDate(value) {
        this.setState({date: value})
    }

    /**
     *
     */
    onClickFilterTours() {
        const pagination = this.state.pagination;
        let whiteLabel_id = this.state.whiteLabel_id;
        let network_id = this.state.network_id;
        let data = {
            pagination: pagination,
            whiteLabel_id: whiteLabel_id,
            network_id: network_id,
            date: this.state.date.format('Y-MM-DD'),
        };

        getManyTours(data)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    tours: json.results,
                    pagination: {
                        ...this.state.pagination,
                        total: json.totalCount,
                    },
                });
            });
    }

    /**
     *
     */
    onClickDeleteFilter() {
        this.setState({
            whiteLabel_id: null,
            network_id: null,
            date: moment()
        }, () => {
            const pagination = {
                current: 1,
                pageSize: 10
            };
            let whiteLabel_id = this.state.whiteLabel_id;
            let network_id = this.state.network_id;
            this.loadTours({
                whiteLabel_id: whiteLabel_id,
                network_id: network_id,
                date: this.state.date.format('Y-MM-DD'),
                pagination
            });
        });
    }

    /**
     *
     * @param {*} value
     */
    handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({dataWhiteLabel: json.results});
                });
        }
    };

    showSelectedCourse(course) {
        this.setState({selectedCourse: course, showDrawerCourse: true});
    }

    /**
     *
     * @param {*} value
     */
    onChangeWhiteLabel(value) {
        this.setState({whiteLabel_id: value});
    }

    render() {
        const {Content} = Layout;
        const {Option} = Select;
        const {tours, pagination, loading} = this.state;

        const user = this.context.global.profile;

        let filterWhiteLabel = null;
        if (user !== null && user.whiteLabel_id === null) {
            filterWhiteLabel = (
                <div>
                    <Space>
                        <div className="filter-title">Marques blanches</div>
                        <Select
                            className="filter"
                            showSearch
                            value={this.state.whiteLabel_id}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={this.handleSearchWhiteLabel}
                            onChange={this.onChangeWhiteLabel}
                            notFoundContent={null}
                            allowClear
                        >
                            {this.state.dataWhiteLabel.map((d) => (
                                <Option key={d.id}>{d.name}</Option>
                            ))}
                        </Select>
                    </Space>
                </div>
            );
        }

        return (
            <div>
                <PageHeader title="Tournées" subTitle="Liste des tournées"/>
                <Content style={{margin: '0 16px 16px 16px'}}>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        <h2 style={{color: "#2FAC66"}}><UnorderedListOutlined/> LISTE</h2>
                        <Divider/>
                        <Space direction="vertical">
                            {filterWhiteLabel}
                            <Space>
                                <div className="filter-title">Date</div>
                                <DatePicker
                                    value={this.state.date}
                                    onChange={this.onChangeDate}
                                    className="filter"
                                    style={{width: '100%'}}
                                    format={'DD/MM/YYYY'}
                                    allowClear={false}
                                />
                            </Space>
                            <Space>
                                <div className="filter-title">Réseaux</div>
                                <Select
                                    className="filter"
                                    showSearch
                                    value={this.state.network_id}
                                    placeholder="Entrer au minimum 3 caractères"
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.handleSearchNetwork}
                                    onChange={this.onChangeNetwork}
                                    notFoundContent={null}
                                >
                                    {this.state.dataNetwork.map((d) => (
                                        <Option key={d.id}>{d.name}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space>
                                <Button
                                    className="filter-button"
                                    type="primary"
                                    onClick={this.onClickFilterTours}
                                >
                                    Filtrer
                                </Button>
                                <Button
                                    style={{backgroundcolor: "#2FAC66"}}
                                    onClick={this.onClickDeleteFilter}
                                >
                                    Annuler
                                </Button>
                            </Space>
                        </Space>
                        <Divider/>
                        <Table
                            columns={this.columns}
                            loading={loading}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            dataSource={tours}
                        />
                    </div>
                </Content>
                <DrawerTour visible={this.state.showDrawerTour} tourId={this.state.selectedTourId}
                            onSelectCourse={this.showSelectedCourse} onClose={() => {
                    this.setState({showDrawerTour: false})
                }}>
                    <DrawerDetailsCourse
                        course={this.state.selectedCourse}
                        showDrawerCourseDetails={this.state.showDrawerCourse}
                        showDrawer={() => {
                            this.setState({
                                showDrawerCourse: false,
                                selectedCourse: null
                            })
                        }}
                    />
                </DrawerTour>
            </div>
        );
    }
}

const TourProgress = (props) => {

    if (!props.tour) {
        return (<></>);
    }

    let title = (<>{props.tour.progression.ended} terminée(s), {props.tour.progression.current} en
        cours, {props.tour.progression.total} au total</>)

    let percent = Math.round((props.tour.progression.ended / props.tour.progression.total) * 100);

    return (
        <Tooltip title={title}>
            <Progress percent={percent} success={{percent: percent}}/>
        </Tooltip>
    )
}

function SortableItem({id, isDragging, action, onSelectCourse}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        opacity: isDragging ? 0.7 : 1,
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none",
        transition: transition ?? "none",
    };

    console.log(action)

    return (
        <div ref={setNodeRef} style={style} {...attributes} >
            <Row style={{
                alignItems: "center"
            }}>
                <Col span={1}  {...listeners} style={{
                    cursor: "grab",
                }} ><div style={{
                    padding: "15px 0 15px"
                }}>
                    <MoreOutlined/>
                </div></Col>
                <Col span={23}>
                    <Row>
                        {(action.type === 'pickup') ? <Col span={24}><HomeOutlined/> Retrait</Col> :
                            <Col span={24}><AimOutlined/> Livraison </Col>}
                    </Row>
                    <Row style={{
                        alignItems: "center"
                    }}>
                        <Col span={17}><ArrowDownOutlined /> <Link
                            to={'/merchant/' + action.course.merchant_id}>{action.course.pickup_area.name}</Link>, {action.course.pickup_area.address}</Col>
                    </Row>
                    <Row style={{
                        alignItems: "center"
                    }}>
                        <Col span={17}><ArrowDownOutlined /> <Link
                            to={'/customer/' + action.course.customer_id}>{action.course.delivery_area.firstname} {action.course.delivery_area.name}</Link>,  {action.course.delivery_area.address}</Col>
                        <Col span={5}>
                            <Tag color="green" key={action.course.status}>
                                {translateStatus[action.course.status]}
                            </Tag>
                        </Col>
                        <Col span={2}>
                            <Button type='primary' onClick={() => {
                                getOneCourse(action.course.id).then((response) => {
                                    return response.json();
                                }).then((course) => {
                                    onSelectCourse(course.course);
                                })
                            }}><EyeOutlined/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider/>
        </div>
    );
}

const DrawerTour = (props) => {

    const [loading, setLoading] = useState(false);
    const [tour, setTour] = useState(null);
    const [sortedActions, setSortedActions] = useState([]);
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    /**
     *
     * @param event
     */
    const onDragEndHandler = (event) => {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = sortedActions.findIndex((item) => item.id === active.id);
            const newIndex = sortedActions.findIndex((item) => item.id === over.id);
            const reorderedItems = [...sortedActions];
            const [movedItem] = reorderedItems.splice(oldIndex, 1);
            reorderedItems.splice(newIndex, 0, movedItem);

            setSortedActions(reorderedItems);

            postTourCoursesOrder(tour.id, {
                order: reorderedItems.map((action) => action.id)
            });
        }

        setActiveId(null);
    }

    /**
     *
     * @param active
     */
    const onDragStartHandler = ({active}) => {

        if (!active) {
            return;
        }

        setActiveId(active.id);
    }

    useEffect(() => {
        if (props.visible === true) {
            setTour(null);
            setSortedActions([]);
        }

        if (props.tourId !== null && props.visible) {
            setLoading(true);
            getOneTour(props.tourId).then((response) => {
                return response.json();
            }).then((tour) => {
                setLoading(false);
                setTour(tour.tour);

                let actions = {};
                for (const course of tour.tour.courses) {
                    actions[course.pickup_area.id] = {
                        ...course.pickup_area,
                        'type': 'pickup',
                        'course': course,
                    };

                    actions[course.delivery_area.id] = {
                        ...course.delivery_area,
                        'type': 'delivery',
                        'course': course,
                    };
                }

                let newSortedActions = [];

                if (tour.tour.courses_order.length !== tour.tour.courses.length * 2) {
                    for (const course of tour.tour.courses) {
                        newSortedActions.push(actions[course.pickup_area.id]);
                        newSortedActions.push(actions[course.delivery_area.id]);
                    }
                } else {
                    for (const sortedAction of tour.tour.courses_order) {
                        newSortedActions.push(actions[sortedAction.action_id])
                    }
                }

                setSortedActions(newSortedActions);

            })
        }
    }, [props.tourId, props.visible])

    let content = 'Chargement ...';

    if (tour && !loading) {
        content = (
            <>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Transporteur : </Col>
                            <Col span={12}>{tour.driver.firstName} {tour.driver.lastName}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row gutter={20}>
                            <Col span={12}>Date : </Col>
                            <Col span={12}>{moment(tour.date).format('DD/MM/Y')}</Col>
                        </Row>
                    </Col>
                </Row>

                <div style={{marginBottom: '25px', marginTop: '25px'}}>
                    <TourProgress tour={tour}/>
                </div>

                <DndContext
                    strategy={verticalListSortingStrategy}
                    collisionDetection={closestCorners}
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    onDragStart={onDragStartHandler}
                    onDragEnd={onDragEndHandler}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={sortedActions.map((action) => action.id)} strategy={verticalListSortingStrategy}>
                        {sortedActions.map(action => {
                            return (<SortableItem handle={true} id={action.id} key={action.id} action={action}
                                                  isDragging={action.id === activeId} onSelectCourse={props.onSelectCourse}/>)
                        })}
                    </SortableContext>
                </DndContext>
            </>
        );
    }

    return (
        <Drawer
            title="Tournée"
            width={720}
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{paddingBottom: 80}}
        >
            {content}
            {props.children}
        </Drawer>
    )
}

export default Tours;
