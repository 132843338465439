import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Row, Col, Drawer, Typography } from 'antd';

const { Text } = Typography;

const DrawerCoursePositionDriver = ({ driver, visible, fromView, onClose }) => {

    if (driver === null) return null;

    let title = 'Informations';
    let cols = [];
    let buttons = [];

    if (fromView !== 'driver') {
        title = 'Informations du livreur';
        cols.push(
            <Col key="0" span={10}><Text strong>Identité :</Text></Col>,
            <Col key="1" span={14}>{driver.identity}</Col>,
            <Col key="2" span={10}><Text strong>Téléphone :</Text></Col>,
            <Col key="3" span={14}>{driver.phone}</Col>
        );

        buttons.push(
            <Button type="primary" style={{ marginTop: '20px', width: '170px' }}>
                <Link to={'/driver/' + driver.id}>Voir le profil</Link>
            </Button>
        );
    }

    cols.push(
        <Col key="4" span={10}><Text strong>Dernière position connue:</Text></Col>,
        <Col key="5" span={14}>{driver.lastTrackingDateTime}</Col>
    );

    return (
        <Drawer
            title={title}
            placement="right"
            onClose={onClose}
            visible={visible}
            width={600}
        >
            <div>
                <Row gutter={[20, 10]}>
                    {cols}
                </Row>
                <Space>
                    {buttons}
                </Space>
            </div>
        </Drawer>
    );
};

export default DrawerCoursePositionDriver;