import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';

import {Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Select, Space} from 'antd';

import {getManyWhiteLabels} from '../../Api/WhiteLabels.js';
import {getManyNetworks} from '../../Api/Networks.js';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const { Option } = Select;

/**
 * 
 * @param {*} props 
 * @returns 
 */
const FormGeneralTab = (props) => {

    let { driver, user } = props;

    const [form] = Form.useForm();

    let [location, setLocation] = React.useState(null);
    const [valueWhiteLabel, setValueWhiteLabel] = React.useState(undefined);
    let [dataWhiteLabel, setDataWhiteLabel] = React.useState([]);
    const [valueNetwork, setValueNetwork] = React.useState(undefined);
    let [dataNetwork, setDataNetwork] = React.useState([]);

    let selectWhiteLabel = null;
    let optionWhiteLabel = dataWhiteLabel.map(d => <Option key={d.id}>{d.name}</Option>);
    let optionNetwork = dataNetwork.map(d => <Option key={d.id}>{d.name}</Option>);

    /**
     * 
     * @param {*} location 
     */
    const onChangeLocation = (location) => {
        setLocation(location);
        form.setFieldsValue({
            address: location.label
        })
    }

    /**
     * 
     * @param {*} value 
     */
    const handleSearchWhiteLabel = (value) => {
        if (value.length > 2) {
            getManyWhiteLabels({
                term: value
            })
                .then((res) => res.json())
                .then((json) => {
                    let dataWhiteLabel = json;
                    setDataWhiteLabel(dataWhiteLabel.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const handleChangeWhiteLabel = (values) => {
        if (values !== undefined) {
            setValueWhiteLabel(values.value);
            if (form.getFieldValue('network_id') !== undefined) {
                form.setFieldsValue({
                    network_id: undefined
                })
                setValueNetwork(undefined)
            }
            setDataNetwork([])
        }

    }

    /**
     * 
     * @param {*} value 
     */
    const handleSearchNetwork = (value) => {
        if (value.length > 2) {
            let whiteLabel_id = valueWhiteLabel;
            getManyNetworks({
                term: value,
                whiteLabel_id: whiteLabel_id,
            })
                .then((res) => res.json())
                .then((json) => {
                    setDataNetwork(dataNetwork = json.results);
                })
        }
    }

    /**
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        let newDriver = driver !== null ? { ...driver } : {};

        newDriver.firstName = values.firstName;
        newDriver.lastName = values.lastName;
        newDriver.phone = values.phone;
        newDriver.email = values.email;
        newDriver.transports = values.transports;
        newDriver.company_status = values.company_status;
        newDriver.password = values.password;
        newDriver.password_confirmation = values.password_confirmation;

        if (driver === null) {
            newDriver.subscription_date = moment();
        } else {
            newDriver.subscription_date = driver.created_at.date;
        }

        if (values.network_id) {
            newDriver.network_id = parseInt(values.network_id.value);
        }

        if (values.tva === undefined) {
            newDriver.tva = '-';
        } else {
            newDriver.tva = values.tva;
        }

        if (values.siret === undefined) {
            newDriver.siret = '-';
        } else {
            newDriver.siret = values.siret;
        }

        if (values.birthday === undefined) {
            newDriver.birthday = '-';
        } else {
            newDriver.birthday = values.birthday;
        }

        if (values.commercial_name === undefined || values.commercial_name === "") {
            newDriver.commercial_name = '-';
        } else {
            newDriver.commercial_name = values.commercial_name;
        }

        let selectedWhiteLabelId = user.whiteLabel_id
        if (user.whiteLabel_id === null && values.whiteLabel_id) {
            selectedWhiteLabelId = parseInt(values.whiteLabel_id.value);
        }

        newDriver.whiteLabel_id = selectedWhiteLabelId;
        if (location === null) {
            newDriver.address = values.address;
            props.formSubmit(newDriver);
        } else {
            geocodeByAddress(location.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    newDriver.address = location.label;
                    newDriver.longitude = lng;
                    newDriver.latitude = lat;

                    props.formSubmit(newDriver);
                });
        }
    };

    /**
     * 
     */
    useEffect(() => {
        if (user !== null && driver === null) {
            if (user.whiteLabel_id !== null) {
                setValueWhiteLabel({
                    whiteLabel_id: {
                        key: user.whiteLabel_id,
                        value: user.whiteLabel_id,
                        label: "",
                    }
                })
            }
            form.setFieldsValue({
                company_status: 'company'
            })
        }

        if (driver !== null) {
            let formValues = {
                firstName: driver.firstName,
                lastName: driver.lastName,
                phone: driver.phone,
                email: driver.email,
                birthday: driver.birthday,
                transports: null,
                notes: driver.notes,
                commercial_name: driver.commercialName,
                siret: driver.siret,
                tva: driver.tva,
                address: driver.address,
                company_status: driver.companyStatus,
                latitude: driver.latitude,
                longitude: driver.longitude,
                whiteLabel_id: {
                    key: null,
                    value: null,
                    label: null,
                },
                network_id: {
                    key: null,
                    value: null,
                    label: null,
                },
            }

            if (driver.white_label !== null) {
                formValues.whiteLabel_id = {
                    key: driver.white_label.id,
                    value: driver.white_label.id,
                    label: driver.white_label.name,
                }

                setValueWhiteLabel({
                    whiteLabel_id: {
                        key: driver.white_label.id,
                        value: driver.white_label.id,
                        label: driver.white_label.name,
                    }
                });
            }

            if (driver.network) {
                formValues.network_id = {
                    key: driver.network.id,
                    value: driver.network.id,
                    label: driver.network.name,
                }
            }

            if (driver.transports !== null) {
                formValues.transports = driver.transports;
            }

            form.setFieldsValue(formValues);

            if (formValues.address !== null) {
                setLocation({
                    "label": formValues.address,
                    "value": {}
                })
            }

        }
    }, [driver]);

    if (user !== null && user.whiteLabel_id === null) {
        selectWhiteLabel = (
            <Form.Item {...layout} label="Marque blanche" name="whiteLabel_id">
                <Select
                    labelInValue={true}
                    showSearch
                    value={valueWhiteLabel}
                    placeholder="Entrer au minimum 3 caractères"
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleSearchWhiteLabel}
                    onChange={handleChangeWhiteLabel}
                    notFoundContent={null}
                    allowClear
                >
                    {optionWhiteLabel}
                </Select>
            </Form.Item>
        )
    }

    return (
        <Form {...layout} form={form} name="layout" onFinish={onFinish} layout='vertical'>
            <Row gutter={20}>
                <Col span={12}>
                    <Divider orientation="left">GENERAL</Divider>
                    <Form.Item label="Prénom" name="firstName" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un prénom.' }]}>
                        <Input name="firstName" />
                    </Form.Item>
                    <Form.Item label="Nom" name="lastName" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un nom de famille.' }]}>
                        <Input name="lastName" />
                    </Form.Item>
                    <Form.Item label="Email" name="email" hasFeedback rules={[{ type: 'email', required: true, message: 'Merci d\'indiquer un email.' }]}>
                        <Input name="email" />
                    </Form.Item>
                    <Form.Item label="Téléphone" name="phone" hasFeedback rules={[{ required: true, message: 'Merci d\'indiquer un numéro de téléphone.' }]}>
                        <Input name="phone" />
                    </Form.Item>
                    <Form.Item label="Password" name="password">
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirmation du password"
                        name="password_confirmation"
                        dependencies={['password']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Les 2 mots de passe ne correspondent pas'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label="Date de naissance" name="birthday" >
                        <Input />
                    </Form.Item>
                    {selectWhiteLabel}
                    <Form.Item label="Réseau" name="network_id" >
                        <Select
                            labelInValue={true}
                            showSearch
                            value={valueNetwork}
                            placeholder="Entrer au minimum 3 caractères"
                            showArrow={true}
                            filterOption={false}
                            onSearch={handleSearchNetwork}
                            notFoundContent={null}
                            allowClear
                        >
                            {optionNetwork}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Moyens de transport" name="transports" >
                        <Checkbox.Group style={{ width: '100%' }} >
                            <Row>
                                <Col span={22}>
                                    <Checkbox value="walk">A pied et transport en commun</Checkbox>
                                </Col>
                                <Col span={22}>
                                    <Checkbox value='bike'>A vélo</Checkbox>
                                </Col>
                                <Col span={22}>
                                    <Checkbox value='cargoBike' >A vélo cargo ou triporteur</Checkbox>
                                </Col>
                                <Col span={22}>
                                    <Checkbox value='motorbike'>En moto ou scooter</Checkbox>
                                </Col>
                                <Col span={22}>
                                    <Checkbox value='car'>En voiture</Checkbox>
                                </Col>
                                <Col span={22}>
                                    <Checkbox value='commercial_vehicle'>En véhicule utilitaire</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Divider orientation="left">PRO</Divider>
                    <Form.Item label="Statut" name="company_status" >
                        <Radio.Group>
                            <Radio value='company'>Société</Radio>
                            <Radio value='single'>Micro</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Nom commercial" name="commercial_name" >
                        <Input name="commercial_name" />
                    </Form.Item>
                    <Form.Item label="SIRET" name="siret" >
                        <Input name="siret" />
                    </Form.Item>
                    <Form.Item label="N° TVA" name="tva" >
                        <Input name="tva" />
                    </Form.Item>
                    <Form.Item label="Adresse" name="address">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDxZByWuUeeXpDrajMFV4NNpvLA2Wcynyk"
                            selectProps={{
                                value: location,
                                onChange: onChangeLocation,
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ['fr'],
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider></Divider>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                    <Button style={{ backgroundcolor: '#2FAC66' }}>
                        <Link to='/drivers'>Annuler</Link>
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormGeneralTab;
